// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, ImageCircle, RelateDeletedModal, Tab, InfoEmployeeTab } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { CODE_MESSAGE_EMPLOYEE_DELETED, NAVIGATE_BACK, QUERY_KEY_TAB } from '~/utils/constants/common';
import { ButtonTypeEnum, ImageCircleTypeEnum, TabNameTransKeyEnum, TabQueryEmployeeDetailEnum } from '~/utils/enum';
import { getUserName } from '~/utils/helper';
import { ErrorData, ITabItem } from '~/utils/interface/common';
import { getDetailUser } from '~/thunks/user/userThunk';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './EmployeeDetail.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const EmployeeDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const { userDetail } = useAppSelector((state) => state.userState);
  //#endregion Selector

  //#region Declare State
  const [isShowEmployeeDeleted, setIsShowEmployeeDeleted] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!employeeId) return;

    handleGetDetailEmployee(employeeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  const employeeDetailTabs: ITabItem[] = useMemo(() => {
    return [{ tabName: TabNameTransKeyEnum.INFO, tabParam: TabQueryEmployeeDetailEnum.INFO }];
  }, [userDetail]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleGetDetailEmployee = (employeeId: string) => {
    loading?.show();

    dispatch(getDetailUser(employeeId))
      .unwrap()
      .then((res) => {})
      .catch((error) => {
        const { code } = error?.response?.data as ErrorData;

        if (code === CODE_MESSAGE_EMPLOYEE_DELETED) setIsShowEmployeeDeleted(!isShowEmployeeDeleted);
      })
      .finally(() => {
        loading?.hide();
      });
  };

  const renderTabContent = useMemo(() => {
    const tabToRender = currentTab || TabQueryEmployeeDetailEnum.INFO;
    switch (tabToRender) {
      case TabQueryEmployeeDetailEnum.INFO:
        return <InfoEmployeeTab />;

      default:
        // Handle Return Tab Later
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, employeeId, userDetail, t]);

  const handleShowEmployeeDeleted = () => {
    setIsShowEmployeeDeleted(!isShowEmployeeDeleted);

    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <div id='employeeDetailPage' className={cx('container')}>
      <div className={cx('mainBody')}>
        {userDetail && (
          <>
            <BaseButton
              onClick={handleBack}
              text={t('common_text_back')}
              typeStyle={ButtonTypeEnum.ONLY_TEXT}
              iconLeft={icons.commonIconPrev}
              height={17}
            />

            <section className={cx('headGroup')}>
              <div className={cx('basicInfoGroup')}>
                <ImageCircle
                  type={userDetail?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                  width={91}
                  height={91}
                  imageUrl={userDetail?.avatarUrl}
                  fontSize={45}
                  firstName={userDetail?.firstName}
                  lastName={userDetail?.lastName}
                />

                <div className={cx('basicInfo')}>
                  <div className={cx('nameGroup')}>
                    <div className={cx('fullName')}>{getUserName(userDetail?.firstName, userDetail?.lastName)}</div>
                  </div>
                </div>
              </div>

              <div className={cx('navTabGroup')}>
                <Tab items={employeeDetailTabs} paddingBottom={30} />
              </div>
            </section>

            <section className={cx('tabContent')}>{renderTabContent}</section>
          </>
        )}
      </div>

      <RelateDeletedModal isOpen={isShowEmployeeDeleted} onClose={handleShowEmployeeDeleted} />
    </div>
  );
};

export default EmployeeDetail;
