// Libs
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import { useLoadScript } from '@react-google-maps/api';
import { useParams } from 'react-router-dom';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import { BaseGoogleMap, BaseTable, LabelValueField, Loading, StatusLabel } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { IDetailTimeClock, ITasksDetailTimeClock } from '~/utils/interface/timeClock';
import {
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  HOURS_UNIT,
} from '~/utils/constants/common';
import { convertMinuteToHours, convertTime, formatAddress, getUserName } from '~/utils/helper';
import { TimeClockQueryTypeEnum, TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import { getDetailTimeClock } from '~/thunks/timeClock/timeClockThunk';
import { googleMapApiKey } from '~/utils/constants/env';
import { ColumnTableType } from '~/utils/interface/common';
// Styles, images, icons
import styles from './TimeClockDetail.module.scss';

type Props = {
  type: string;
};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<ITasksDetailTimeClock>[] => {
  return [
    {
      key: 'startTime',
      title: t('time_clock_detail_task_start_time_label'),
      render(value, record) {
        return (
          <>
            {record?.startTime
              ? moment(record?.startTime, TimeFormatEnum.HH_MM_SS).format(TimeFormatEnum.HOUR_MINUTE_AM_PM_NORMAL)
              : EMPTY_STRING}
          </>
        );
      },
    },
    {
      key: 'name',
      title: t('time_clock_detail_task_name_label'),
      render(value, record) {
        return <>{record?.name || EMPTY_STRING}</>;
      },
    },
    {
      key: 'description',
      title: t('time_clock_detail_task_description_label'),
      render(value, record) {
        return <>{record?.detail || EMPTY_STRING}</>;
      },
    },
  ];
};

const TimeClockDetail = (props: Props) => {
  //#region Destructuring Props
  const { type } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { timeClockId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [timeClockDetail, setTimeClockDetail] = useState<IDetailTimeClock>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const position = useMemo(() => {
    if (type === TimeClockQueryTypeEnum.CAREGIVER) {
      const { lat, lng } = timeClockDetail?.locationClient || {};
      return lat && lng ? { lat: Number(lat), lng: Number(lng) } : undefined;
    }
    const { lat, lng } = timeClockDetail || {};
    return lat && lng ? { lat: Number(lat), lng: Number(lng) } : undefined;
  }, [type, timeClockDetail]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!timeClockId) return;

    handleGetDetailTimeCock(timeClockId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeClockId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailTimeCock = (timeClockId: string) => {
    setIsLoading(true);

    dispatch(getDetailTimeClock(timeClockId))
      .unwrap()
      .then((response) => {
        const responses: IDetailTimeClock = response?.data;
        setTimeClockDetail(responses);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  //#endregion Handle Function

  return (
    <div id='timeClockDetailComponent' className={cx('timeClockDetailComponent')}>
      <div className={cx('bodyWarp')}>
        <div className={cx('infoDetailsWrap')}>
          <LabelValueField
            label={t('time_clock_detail_name_label')}
            value={getUserName(
              timeClockDetail?.account?.firstName,
              timeClockDetail?.account?.middleName,
              timeClockDetail?.account?.lastName
            )}
          />

          {type === TimeClockQueryTypeEnum.EMPLOYEE && (
            <LabelValueField
              label={t('time_clock_detail_task_label')}
              value={timeClockDetail?.task?.description || EMPTY_STRING}
            />
          )}

          {type === TimeClockQueryTypeEnum.CAREGIVER && (
            <LabelValueField
              label={t('time_clock_detail_shift_label')}
              value={getUserName(
                timeClockDetail?.client?.firstName,
                timeClockDetail?.client?.middleName,
                timeClockDetail?.client?.lastName
              )}
            />
          )}

          <LabelValueField
            label={t('time_clock_detail_date_label')}
            value={
              timeClockDetail?.startTime
                ? convertTime(timeClockDetail?.startTime, TimeFormatEnum.MM_DD_YYYY)
                : EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('time_clock_detail_hours_label')}
            value={
              timeClockDetail?.duration
                ? `${convertMinuteToHours(Number(timeClockDetail.duration))} ${HOURS_UNIT}`
                : EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('time_clock_detail_check_in_label')}
            value={
              timeClockDetail?.startTime
                ? convertTime(timeClockDetail?.startTime, TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)
                : EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('time_clock_detail_check_out_label')}
            value={
              timeClockDetail?.endTime
                ? convertTime(timeClockDetail?.endTime, TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)
                : EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('time_clock_detail_status_label')}
            value={
              timeClockDetail?.status ? (
                <StatusLabel bgOpacity={0.1} label={timeClockDetail?.status} type='text' />
              ) : (
                EMPTY_STRING
              )
            }
          />

          {type === TimeClockQueryTypeEnum.CAREGIVER && (
            <>
              <LabelValueField
                label={t('time_clock_detail_notes_label')}
                value={timeClockDetail?.comment || EMPTY_STRING}
                isLongValue
              />
              <div className={cx('taskWrap')}>
                <div className={cx('taskLabel')}>{t('time_clock_detail_task_of_shift_label')}</div>
                {timeClockDetail?.tasks && timeClockDetail?.tasks?.length > DEFAULT_NUMBER_ZERO && (
                  <BaseTable columns={columns(t)} dataSource={timeClockDetail?.tasks || []} />
                )}
              </div>
            </>
          )}
        </div>
        <div className={cx('mapSection')}>
          <div className={cx('viewAddress')}>
            <span className={cx('textLabel')}>{t('time_clock_detail_location_label')}</span>
            <span className={cx('valueContent')}>
              <span className={cx('valueContent')}>
                {type === TimeClockQueryTypeEnum.CAREGIVER
                  ? timeClockDetail?.locationClient?.address
                  : formatAddress({ ...timeClockDetail })}
              </span>
            </span>
          </div>

          <div className={cx('mapViewContainer')}>
            {isLoaded && <BaseGoogleMap position={position} triggerLocate />}
          </div>
        </div>
      </div>

      {isLoading && <Loading />}
    </div>
  );
};

export default TimeClockDetail;
