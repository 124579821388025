// Libs
import { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseFilter,
  BasePagination,
  BaseSelect,
  BaseTable,
  ChangeStageModal,
  ConvertToClientModal,
  FormSendEmailModal,
  InputSearch,
  ModalChangeStatus,
  ModalUnderDevelopment,
  RingCentralConfirmModal,
  SendEmailMultipleModal,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';
// Others
import {
  IFilterClient,
  IListClient,
  IListQueryParamsClients,
  IPayloadChangeStatusClient,
} from '~/utils/interface/crm/clients';
import {
  ColumnTableType,
  IBaseOption,
  IFormSendMail,
  IListDataResponse,
  IListQueryParams,
  IPaginationResponse,
} from '~/utils/interface/common';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
  optionAllCaregiver,
  OPTIONS_CHANGE_STATUS,
  optionsStatusProspect,
} from '~/utils/constants/common';
import useDebounce from '~/utils/customHook';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  CRMEnum,
  StorageEnum,
  TranslationEnum,
  StatusFilterEnum,
  CLientStatusEnum,
  StageTypeEnum,
  StatusEnum,
  ConnectionStatusEnum,
} from '~/utils/enum';
import { formatPhoneNumber, getUserName, removeEmptyObjects } from '~/utils/helper';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData, RingCentralContext } from '~/context';
import { clientActions, selectIsRefreshClientList } from '~/thunks/crm/clients/clientsSlice';
import {
  adminRouteAbsolute,
  caregiverRouteAbsolute,
  staffRouteAbsolute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';
import { changeStageClient, changeStatusClient, getListClients } from '~/thunks/crm/clients/clientsThunk';
import { sendEmailCaregivers } from '~/thunks/caregiver/caregiverThunk';
import { IQueryParamsStage } from '~/utils/interface/stage';
import { getListStage } from '~/thunks/stage/stageThunk';
import { IChangeStage } from '~/utils/interface/applicant';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
// Styles, images, icons
import styles from './CRMProspect.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleCall: (record: IListClient) => void,
  handleSendEmail: (record: IListClient) => void,
  handleViewDetails: (id: string) => void,
  handleSendMessage: (record: IListClient) => void,
  handleChangeStatus: (record: IListClient) => void,
  onChangeStage: (record: IListClient) => void,
  handleConvertToClient: (record: IListClient) => void,
  handleEditProspect: (id: string) => void
): ColumnTableType<IListClient>[] => {
  return [
    {
      key: 'prospectName',
      title: t('crm_prospect_table_name_label'),
      render: (_, record: IListClient) => {
        return <>{getUserName(record?.firstName, record?.middleName, record?.lastName)}</>;
      },
    },
    {
      key: 'phoneNumber',
      title: t('crm_prospect_table_phone_number_label'),
      dataIndex: 'phoneNumber',
      render(value, record, index) {
        return <>{record?.phoneNumber ? formatPhoneNumber(record?.phoneNumber) : EMPTY_STRING}</>;
      },
    },
    // {
    //   key: 'primaryManager',
    //   title: t('crm_prospect_table_primary_manager_label'),
    //   dataIndex: 'primaryManager',
    //   render(value, record, index) {
    //     return <>{record.primaryManager || EMPTY_STRING}</>;
    //   },
    // },
    {
      key: 'stage',
      title: t('crm_prospect_table_stage_label'),
      render(value, record, index) {
        return <>{record.stage?.name || EMPTY_STRING}</>;
      },
    },
    // {
    //   key: 'status',
    //   title: t('crm_prospect_table_status_label'),
    //   dataIndex: 'status',
    //   render(value, record, index) {
    //     if (!record.status) {
    //       return EMPTY_STRING;
    //     }

    //     let status = EMPTY_STRING;

    //     switch (record.status) {
    //       case CLientStatusEnum.ACTIVE:
    //         status = ViewCLientStatusEnum.ACTIVATE;
    //         break;
    //       case CLientStatusEnum.DEACTIVATE:
    //         status = ViewCLientStatusEnum.DEACTIVATE;
    //         break;
    //       default:
    //         status = record.status;
    //     }

    //     return <StatusLabel bgOpacity={0.1} key={index} label={status} />;
    //   },
    //   width: '20%',
    // },
    {
      key: 'referrer',
      title: t('crm_prospect_table_referrer_label'),
      render: (_, record: IListClient) => {
        const renderName = () => {
          return (
            record?.referrer?.organizationName ||
            getUserName(record?.referrer?.firstName, record?.referrer?.middleName, record?.referrer?.lastName)
          );
        };

        return <>{renderName()}</>;
      },
    },
    {
      key: 'action',
      title: t('crm_prospect_table_action_label'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isCall: true,
              isSendMessage: true,
              isSendEmail: true,
              isDelete: false,
              isDeactivate: false,
              isChangeStatus: true,
              isConvertToClient: record.status === StatusEnum.ACTIVE,
              isChangeStage: true,
            }}
            onView={() => handleViewDetails(record.id)}
            onEdit={() => handleEditProspect(record.id)}
            onCall={() => handleCall(record)}
            onSendEmail={() => handleSendEmail(record)}
            onSendMessage={() => handleSendMessage(record)}
            onChangeStatus={() => handleChangeStatus(record)}
            onChangeStage={() => onChangeStage(record)}
            onConvertToClient={() => handleConvertToClient(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const CRMProspect = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const role = localStorage.getItem(StorageEnum.ROLE);
  const { ringCentralStatus, executeCall, sendSMS } = useContext(RingCentralContext);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshClientsList = useSelector(selectIsRefreshClientList);
  //#endregion Selector

  //#region Declare State
  const [prospectList, setProspectList] = useState<IListClient[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [isShowModalConvertToClient, setIsShowModalConvertToClient] = useState<boolean>(false);
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [prospectSelected, setProspectSelected] = useState<IListClient | null>(null);
  const [isShowSendMail, setIsShowSendMail] = useState<boolean>(false);
  const [prospectId, setProspectId] = useState<string>();
  const [isShowChangeStatus, setIsShowChangeStatus] = useState<boolean>(false);
  const statusParams = useMemo<string>(() => String(params?.status || EMPTY_STRING), [params?.status]);
  const locationParams = useMemo<string>(() => String(params?.locationId || EMPTY_STRING), [params?.locationId]);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState<boolean>(false);
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState<boolean>(false);
  const [isChangeStage, setIsChangeStage] = useState<boolean>(false);
  const [listStage, setListStage] = useState<IBaseOption[]>([]);
  const [locations, setLocations] = useState<IBaseOption[]>([]);
  const [openDisconnectConfirm, setOpenDisconnectConfirm] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListLocations();
  }, []);

  useEffect(() => {
    if (!isChangeStage) return;

    const paramsStage: IQueryParamsStage = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      type: StageTypeEnum.PROSPECT,
    };
    handleGetListStage(paramsStage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeStage]);

  useEffect(() => {
    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_LIMIT_PAGE,
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
      type: [CRMEnum.PROSPECT],
    };

    handleGetListProspect(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_LIMIT_PAGE.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshClientsList) return;

    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_LIMIT_PAGE,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
      type: [CRMEnum.PROSPECT],
    };

    handleGetListProspect(newParams);
    dispatch(clientActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshClientsList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleViewDetails = (id: string) => {
    if (!id) return;

    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.crmProspect}/${id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.crmProspect}/${id}`);
        return;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.crmProspect}/${id}`);
        return;

      case AccountRoleCodesEnum.CAREGIVER:
        navigate(`${caregiverRouteAbsolute.crmProspect}/${id}`);
        return;

      default:
        return;
    }
  };

  const handleCall = async (record: IListClient) => {
    if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) {
      setOpenDisconnectConfirm(true);
      return;
    }

    if (record?.phoneNumber) {
      executeCall && executeCall(record?.phoneNumber);
    }
  };

  const handleSendMessage = (record: IListClient) => {
    if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) {
      setOpenDisconnectConfirm(true);
      return;
    }

    if (record?.phoneNumber) {
      sendSMS && sendSMS(record?.phoneNumber);
    }
  };

  const handleShowSendEmail = (record: IListClient) => {
    if (!record) return;

    setProspectSelected(record);
    setIsShowSendMail(true);
  };

  const handleCloseSendEmail = () => {
    setProspectSelected(null);
    setIsShowSendMail(false);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleGetListProspect = (params: IListQueryParamsClients) => {
    loading?.show();

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IListClient[]> = res?.data;
        setProspectList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleSortBy = () => {
    // TODO - DuyGRF-ABD: Handle Sort By
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleFilter = () => {
    // TODO - DuyGRF-ABD: Handle Filter
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleChangeStatus = (record: IListClient) => {
    if (!record) return;

    setProspectSelected(record);
    setIsShowChangeStatus(true);
  };

  const handleAddProspect = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.addProspect}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.addProspect}`);
        return;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.addProspect}`);
        return;
    }
  };

  const handleCloseDisconnectConfirm = () => {
    setOpenDisconnectConfirm(false);
  };

  const handleNavigateToRingCentral = () => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(superAdminRouteAbsolute.settings);
        break;

      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.settings);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(staffRouteAbsolute.settings);
        break;

      case AccountRoleCodesEnum.CAREGIVER:
        break;

      default:
        break;
    }
  };

  const handleProspectRowClick = (data: IListClient) => {
    if (!data || !data?.id) return;

    handleViewDetails(data?.id);
  };

  const handleConvertToClient = (data: IListClient) => {
    setIsShowModalConvertToClient(true);
    setProspectId(data.id);
  };

  const handleCloseModalConvertToClient = () => {
    setIsShowModalConvertToClient(false);
  };

  const handleCloseChangeStatus = () => {
    setProspectSelected(null);
    setIsShowChangeStatus(false);
  };

  const handleSubmitFilter = (valueFilter: IFilterClient | undefined) => {
    if (!valueFilter) {
      return;
    }

    const { status, referralRole, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.status === StatusFilterEnum.ALL ? {} : { status: valueFilter?.status }),
      ...(valueFilter?.locationId === StatusFilterEnum.ALL ? {} : { locationId: valueFilter?.locationId }),
    });
  };

  const handleChangeStatusProspect = (status: string) => {
    setIsLoadingChangeStatus(true);

    if (!prospectSelected) return;

    const payloadClientStatus: IPayloadChangeStatusClient = {
      clientId: prospectSelected.id,
      body: {
        status: status as CLientStatusEnum,
      },
    };

    dispatch(changeStatusClient(payloadClientStatus))
      .unwrap()
      .then(() => {
        dispatch(clientActions.setRefreshList(true));
        handleCloseChangeStatus();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingChangeStatus(false);
      });
  };

  const handleEditProspect = (prospectId: string) => {
    if (!prospectId) return;

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.editProspect}/${prospectId}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.editProspect}/${prospectId}`);
        return;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.editProspect}/${prospectId}`);
        return;
    }
  };

  const handleSendEmail = async (dataEmail: IFormSendMail) => {
    if (!prospectSelected?.id) return;
    setIsLoadingSendEmail(true);

    const formData = new FormData();

    const formDataFields = {
      from: dataEmail?.from || EMPTY_STRING,
      to: JSON.stringify(dataEmail?.to || []),
      cc: JSON.stringify(dataEmail?.cc || []),
      bcc: JSON.stringify(dataEmail?.bcc || []),
      subject: dataEmail?.subject || EMPTY_STRING,
      content: dataEmail?.content || EMPTY_STRING,
    };

    const dataFormatted = removeEmptyObjects(formDataFields);

    Object.entries(dataFormatted).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    if (dataEmail?.files) {
      dataEmail?.files?.forEach((file) => {
        file && formData.append('files', file);
      });
    }

    dispatch(sendEmailCaregivers(formData))
      .unwrap()
      .then((res) => {
        handleCloseSendEmail();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingSendEmail(false);
      });
  };

  const handleShowChangeStage = (record: IListClient) => {
    if (record) setProspectSelected(record);

    setIsChangeStage(true);
  };

  const handleCloseChangeStage = () => {
    setIsChangeStage(false);
    setProspectSelected(null);
  };

  const handleGetListStage = (params: IQueryParamsStage) => {
    dispatch(getListStage(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res?.data;
        const formattedData: IBaseOption[] = responses.map((res) => {
          return {
            label: res.name || EMPTY_STRING,
            value: res.id,
          };
        });

        setListStage(formattedData);
      })
      .catch((error) => {});
  };

  const handleSubmitChangeStage = (stage: IChangeStage) => {
    if (!stage.stageId || !prospectSelected?.id) return;

    const newData = {
      clientId: prospectSelected?.id,
      body: stage,
    };

    return dispatch(changeStageClient(newData))
      .unwrap()
      .then((res) => {
        dispatch(clientActions.setRefreshList(true));
      })
      .catch(() => {})
      .finally(() => {
        setIsChangeStage(false);
      });
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = [
          optionAllCaregiver,
          ...(res.data?.responses?.map((data) => ({
            label: data.name || EMPTY_STRING,
            value: data.id,
          })) || []),
        ];
        setLocations(listLocation);
      })
      .catch((err) => {})
      .finally(() => {});
  };
  //#endregion Handle Function

  return (
    <div id='CRMProspectTemplate' className={cx('container')}>
      <ToolBar
        title={
          <div className={cx('crmTitle')}>
            <span>{t('crm_prospect_title')}</span>

            {ringCentralStatus !== ConnectionStatusEnum.CONNECTED && (
              <span className={cx('crmRingCentralStatus')} onClick={handleNavigateToRingCentral}>
                {t('ring_central_confirm_disconnect_title')}
              </span>
            )}
          </div>
        }
      >
        {/* <BaseButton
          iconLeft={icons.commonIconSort}
          text={t('common_text_sort')}
          width={84}
          height={36}
          onClick={handleSortBy}
        />
        <BaseButton
          iconLeft={icons.commonIconSort}
          text={t('common_text_filter')}
          width={67}
          height={36}
          onClick={handleFilter}
        /> */}
        <InputSearch
          height={36}
          placeholder={t('common_placeholder_search_by_name')}
          onChange={handleChangeSearch}
          value={searchKey}
        />
        <BaseFilter<IFilterClient>
          defaultValue={{ status: StatusFilterEnum.ALL, locationId: StatusFilterEnum.ALL }}
          onApply={handleSubmitFilter}
          valueFilter={{
            status: statusParams || StatusFilterEnum.ALL,
            locationId: locationParams || StatusFilterEnum.ALL,
          }}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('filterWrap')}>
                <div className={cx('contentFilter')}>
                  <span className={cx('statusLabel')}>{t('crm_filter_client_status_title')}</span>
                  <BaseSelect
                    width={175}
                    value={valueFilter?.status}
                    options={optionsStatusProspect}
                    onChange={(option) => {
                      onChange({
                        name: 'status',
                        value: option?.value.toString(),
                      });
                    }}
                  />
                </div>

                <div className={cx('contentFilter')}>
                  <span className={cx('statusLabel')}>{t('crm_filter_client_location_title')}</span>
                  <BaseSelect
                    width={175}
                    value={valueFilter?.locationId}
                    options={locations}
                    placeholder={t('common_select_placeholder')}
                    mode='search'
                    onChange={(option) => {
                      onChange({
                        name: 'locationId',
                        value: option?.value.toString(),
                      });
                    }}
                  />
                </div>
              </div>
            );
          }}
        </BaseFilter>
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('crm_add_prospect_title')}
          height={36}
          onClick={handleAddProspect}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(
              t,
              handleCall,
              handleShowSendEmail,
              handleViewDetails,
              handleSendMessage,
              handleChangeStatus,
              handleShowChangeStage,
              handleConvertToClient,
              handleEditProspect
            )}
            dataSource={prospectList ?? []}
            onClickRow={handleProspectRowClick}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowSendMail && (
        <SendEmailMultipleModal
          isOpen={isShowSendMail}
          onClose={handleCloseSendEmail}
          onSendEmail={handleSendEmail}
          isLoading={isLoadingSendEmail}
          defaultEmails={prospectSelected?.email ? [prospectSelected?.email] : []}
        />
      )}

      {isShowModalConvertToClient && (
        <ConvertToClientModal
          isOpen={isShowModalConvertToClient}
          onClose={handleCloseModalConvertToClient}
          prospectId={prospectId}
        />
      )}

      {isShowChangeStatus && (
        <ModalChangeStatus
          isOpen={isShowChangeStatus}
          dataDefault={prospectSelected?.status || EMPTY_STRING}
          dataOption={OPTIONS_CHANGE_STATUS}
          onClose={handleCloseChangeStatus}
          onSubmit={handleChangeStatusProspect}
          isLoading={isLoadingChangeStatus}
        />
      )}

      {openDisconnectConfirm && (
        <RingCentralConfirmModal
          isOpen={openDisconnectConfirm}
          title={t('ring_central_confirm_disconnect_title')}
          description={t('ring_central_confirm_disconnect_description')}
          confirm={t('ring_central_confirm_disconnect_confirm')}
          onClose={handleCloseDisconnectConfirm}
          onConfirm={handleNavigateToRingCentral}
        />
      )}

      {isChangeStage && (
        <ChangeStageModal
          dataDefault={prospectSelected?.stage?.id || EMPTY_STRING}
          isOpen={isChangeStage}
          onClose={handleCloseChangeStage}
          dataOption={listStage}
          onSubmit={handleSubmitChangeStage}
        />
      )}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default CRMProspect;
