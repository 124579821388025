// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseFilter,
  BasePagination,
  BaseSelect,
  BaseTable,
  ChangeStageModal,
  ConfirmModal,
  ImageCircle,
  InputSearch,
  ModalChangeStatus,
  ModalUnderDevelopment,
  SendEmailMultipleModal,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';
// Others
import {
  ColumnTableType,
  IBaseOption,
  IFormSendMail,
  IListDataResponse,
  IListQueryParams,
  IPaginationResponse,
} from '~/utils/interface/common';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  ImageCircleTypeEnum,
  StatusFilterEnum,
  TranslationEnum,
  StageTypeEnum,
  StatusEnum,
} from '~/utils/enum';
import { formatPhoneNumber, getUserName, removeEmptyObjects } from '~/utils/helper';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
  optionAllCaregiver,
  optionsStatusRequestApplicant,
  optionStatusRequestApplicant,
} from '~/utils/constants/common';
import { IApplicant, IChangeStage, IDefaultFilterApplicant } from '~/utils/interface/applicant';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import useDebounce from '~/utils/customHook';
import {
  convertToCaregiver,
  changeStageApplicant,
  getListApplicant,
  changeStatusApplicant,
} from '~/thunks/caregivers/applicant/applicantThunk';
import { applicantActions, selectIsRefreshApplicantList } from '~/thunks/caregivers/applicant/applicantSlice';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import { IQueryParamsStage } from '~/utils/interface/stage';
import { getListStage } from '~/thunks/stage/stageThunk';
import { sendEmailCaregivers } from '~/thunks/caregiver/caregiverThunk';
// Styles, images, icons
import styles from './RequestApplicant.module.scss';
import { icons } from '~/assets';

type Props = {
  role: string;
};

const cx = classNames.bind(styles);

const columns = ({
  t,
  onView,
  onEdit,
  onSendEmail,
  onChangeStatus,
  onChangeStage,
  onConvertToCaregiver,
}: {
  t: TFunction<TranslationEnum.TRANSLATION>;
  onView: (record: IApplicant) => void;
  onEdit: (record: IApplicant) => void;
  onSendEmail: (record: IApplicant) => void;
  onChangeStatus: (record: IApplicant) => void;
  onChangeStage: (record: IApplicant) => void;
  onConvertToCaregiver: (record: IApplicant) => void;
}): ColumnTableType<IApplicant>[] => {
  return [
    {
      key: 'name',
      title: t('caregiver_table_title_name'),
      render(_, record: IApplicant) {
        return !record.firstName && !record.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record?.avatarUrl}
              firstName={record?.firstName}
              lastName={record?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.firstName, record?.middleName, record?.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'address',
      title: t('caregiver_table_title_address'),
      dataIndex: 'address',
      render(_, record: IApplicant) {
        return (
          <div className={cx('textBase')}>
            {record?.address ? <span>{record.address}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'locations',
      title: t('caregiver_table_title_location'),
      dataIndex: 'locations',
      render(_, record: IApplicant) {
        return record.locations?.map((location) => location.name).join(', ') || EMPTY_STRING;
      },
    },
    {
      key: 'referrer',
      title: t('caregiver_table_title_referrer'),
      dataIndex: 'referrer',
      render(_, record: IApplicant) {
        return (
          record?.referrer?.organizationName ||
          getUserName(record?.referrer?.firstName, record?.referrer?.middleName, record?.referrer?.lastName)
        );
      },
    },
    {
      key: 'stage',
      title: t('caregiver_table_title_stage'),
      dataIndex: 'stage',
      render(_, record: IApplicant) {
        return <>{record?.stage?.name || EMPTY_STRING}</>;
      },
    },
    {
      key: 'mobilePhone',
      title: t('caregiver_table_title_phone_number'),
      dataIndex: 'mobilePhone',
      render(_, record: IApplicant) {
        return <>{record?.mobilePhone ? formatPhoneNumber(record?.mobilePhone) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'action',
      title: t('caregiver_table_title_action'),
      render(_, record: IApplicant) {
        return (
          <ThreeDotTableOptions
            permissions={{
              isDelete: false,
              isSendEmail: true,
              isChangeStage: true,
              isConvertToCaregiver: record.status === StatusEnum.ACTIVE,
              isChangeStatus: true,
            }}
            onView={() => onView(record)}
            onEdit={() => onEdit(record)}
            onSendEmail={() => onSendEmail(record)}
            onChangeStatus={() => onChangeStatus(record)}
            onChangeStage={() => onChangeStage(record)}
            onConvertToCaregiver={() => onConvertToCaregiver(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const RequestApplicant = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const statusParams = useMemo<string>(() => String(params?.status || EMPTY_STRING), [params?.status]);
  const locationParams = useMemo<string>(() => String(params?.locationId || EMPTY_STRING), [params?.locationId]);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshApplicantList = useSelector(selectIsRefreshApplicantList);
  //#endregion Selector

  //#region Declare State
  const [applicantList, setApplicantList] = useState<IApplicant[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [isConvertToCaregiver, setIsConvertToCaregiver] = useState<boolean>(false);
  const [recordRequestApplicant, setRecordRequestApplicant] = useState<IApplicant>();
  const [isChangeStage, setIsChangeStage] = useState<boolean>(false);
  const [isChangeStatus, setIsChangeStatus] = useState<boolean>(false);
  const [listStage, setListStage] = useState<IBaseOption[]>([]);
  const [isShowSendEmailModal, setIsShowSendEmailModal] = useState<boolean>(false);
  const [locationList, setLocationList] = useState<IBaseOption[]>([]);
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState<boolean>(false);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!isChangeStage) return;

    const paramsStage: IQueryParamsStage = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      type: StageTypeEnum.APPLICANT,
    };
    handleGetListStage(paramsStage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeStage]);

  useEffect(() => {
    handleGetListLocations();
  }, []);

  useEffect(() => {
    const { status, locationId, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
      ...(params?.locationId === StatusFilterEnum.ALL ? {} : { locationId: locationId }),
    };
    handleGetListApplicant(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshApplicantList) return;

    const { status, locationId, ...restParams } = params;
    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
      ...(params?.locationId === StatusFilterEnum.ALL ? {} : { locationId: locationId }),
    };

    handleGetListApplicant(newParams);
    dispatch(applicantActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshApplicantList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListStage = (params: IQueryParamsStage) => {
    dispatch(getListStage(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res?.data;
        const formattedData: IBaseOption[] = responses.map((res) => {
          return {
            label: res.name || EMPTY_STRING,
            value: res.id,
          };
        });

        setListStage(formattedData);
      })
      .catch((error) => {});
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => ({
          label: data.name || EMPTY_STRING,
          value: data.id,
        }));
        setLocationList([optionAllCaregiver, ...listLocation]);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleGetListApplicant = (payload: IListQueryParams) => {
    loading?.show();

    dispatch(getListApplicant(payload))
      .unwrap()
      .then((res) => {
        loading?.hide();
        const { responses, pagination }: IListDataResponse<IApplicant[]> = res?.data;
        setApplicantList(responses);
        setPagination(pagination);
      })
      .catch((err) => {
        loading?.hide();
      });
  };

  // const handleBack = () => {
  //   navigate(getRoutesByRole(role)?.caregiver ?? EMPTY_STRING);
  // };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handleAddApplicant = () => {
    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.createRequestApplicant);
        break;

      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(superAdminRouteAbsolute.createRequestApplicant);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(staffRouteAbsolute.createRequestApplicant);
        break;
    }
  };

  const handlePaginationChange = (page: number) => {
    if (!page) return;
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleFilterChange = (appliedFilter: IDefaultFilterApplicant | undefined) => {
    if (!appliedFilter) {
      return;
    }

    const { status, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(appliedFilter?.status ? { status: appliedFilter?.status } : {}),
      ...(appliedFilter?.locationId ? { locationId: appliedFilter?.locationId } : {}),
    });
  };

  const handleClickUnderdevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };

  const handleViewDetail = (data: IApplicant) => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.requestApplicant}/${data.id}`);
        break;

      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.requestApplicant}/${data.id}`);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.requestApplicant}/${data.id}`);
        break;
    }
  };

  const handleApplicantRowClick = (data: IApplicant) => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.requestApplicant}/${data.id}`);
        break;

      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.requestApplicant}/${data.id}`);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.requestApplicant}/${data.id}`);
        break;
    }
  };

  const handleEdit = (data: IApplicant) => {
    if (!data.id) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.editRequestApplicant}/${data.id}`);
        break;

      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.editRequestApplicant}/${data.id}`);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.editRequestApplicant}/${data.id}`);
        break;
    }
  };

  const handleSendEmail = (data: IApplicant) => {
    if (data) setRecordRequestApplicant(data);

    setIsShowSendEmailModal(true);
  };

  const handleChangeStage = (data: IApplicant) => {
    if (data) setRecordRequestApplicant(data);

    setIsChangeStage(true);
  };

  const handleCloseChangeStage = () => {
    setIsChangeStage(false);
    setRecordRequestApplicant(undefined);
  };

  const handleShowConvertToCaregiver = (data: IApplicant) => {
    if (data) setRecordRequestApplicant(data);

    setIsConvertToCaregiver(true);
  };

  const handleConvertToCaregiver = () => {
    if (!recordRequestApplicant?.id) return;

    return dispatch(convertToCaregiver(recordRequestApplicant.id))
      .unwrap()
      .then((res) => {
        dispatch(applicantActions.setRefreshList(true));
      })
      .catch(() => {})
      .finally(() => {
        setIsConvertToCaregiver(false);
      });
  };

  const handleCloseConvertToCaregiver = () => {
    setIsConvertToCaregiver(false);
    setRecordRequestApplicant(undefined);
  };

  const handleSubmitChangeStage = (stage: IChangeStage) => {
    if (!stage.stageId || !recordRequestApplicant?.id) return;

    return dispatch(changeStageApplicant({ applicantId: recordRequestApplicant?.id, body: stage }))
      .unwrap()
      .then((res) => {
        dispatch(applicantActions.setRefreshList(true));
      })
      .catch(() => {})
      .finally(() => {
        setIsChangeStage(false);
      });
  };

  const handleCloseSendEmail = () => {
    setIsShowSendEmailModal(false);
    setRecordRequestApplicant(undefined);
  };

  const handleSendEmailApplicant = async (data: IFormSendMail) => {
    if (!recordRequestApplicant?.id) return;
    setIsLoadingSendEmail(true);

    const formData = new FormData();

    const formDataFields = {
      from: data?.from || EMPTY_STRING,
      to: JSON.stringify(data?.to || []),
      cc: JSON.stringify(data?.cc || []),
      bcc: JSON.stringify(data?.bcc || []),
      subject: data?.subject || EMPTY_STRING,
      content: data?.content || EMPTY_STRING,
    };

    const dataFormatted = removeEmptyObjects(formDataFields);

    Object.entries(dataFormatted).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    if (data?.files) {
      data?.files?.forEach((file) => {
        file && formData.append('files', file);
      });
    }

    dispatch(sendEmailCaregivers(formData))
      .unwrap()
      .then((res) => {
        handleCloseSendEmail();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingSendEmail(false);
      });
  };

  const handleShowChangeStatus = (data: IApplicant) => {
    if (data) setRecordRequestApplicant(data);

    setIsChangeStatus(true);
  };

  const handleCloseChangeStatus = () => {
    setIsChangeStatus(false);
    setRecordRequestApplicant(undefined);
  };

  const handleChangeStatus = (status: string) => {
    if (!status || !recordRequestApplicant) return;

    setIsLoadingChangeStatus(true);

    const newData = {
      applicantId: recordRequestApplicant?.id,
      body: {
        status: status as StatusFilterEnum,
      },
    };

    dispatch(changeStatusApplicant(newData))
      .unwrap()
      .then((res) => {
        dispatch(applicantActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        handleCloseChangeStatus();
        setIsLoadingChangeStatus(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='requestApplicantPage' className={cx('requestApplicantContainer')}>
      <div className={cx('headerToolBar')}>
        <ToolBar title={t('caregiver_table_title_page_request_applicant')}>
          <InputSearch
            height={36}
            placeholder={t('caregiver_page_search_by_name_title_request_applicant')}
            onChange={handleChangeSearch}
            value={searchKey || EMPTY_STRING}
          />

          <BaseFilter<IDefaultFilterApplicant>
            onApply={handleFilterChange}
            defaultValue={{ status: StatusFilterEnum.ALL, locationId: StatusFilterEnum.ALL }}
            valueFilter={{
              status: statusParams || StatusFilterEnum.ALL,
              locationId: locationParams || StatusFilterEnum.ALL,
            }}
          >
            {({ valueFilter, onChange }) => {
              return (
                <div className={cx('filterWrap')}>
                  <div className={cx('contentFilter')}>
                    <span className={cx('statusLabel')}>{t('request_applicant_page_filter_by_status_title')}</span>
                    <BaseSelect
                      value={valueFilter?.status}
                      options={optionsStatusRequestApplicant}
                      onChange={(option) => {
                        onChange({
                          name: 'status',
                          value: option?.value.toString(),
                        });
                      }}
                      width={200}
                    />
                  </div>

                  <div className={cx('contentFilter')}>
                    <span className={cx('statusLabel')}>{t('request_applicant_page_filter_by_location_title')}</span>
                    <BaseSelect
                      value={valueFilter?.locationId}
                      options={locationList}
                      onChange={(option) => {
                        onChange({
                          name: 'locationId',
                          value: option?.value.toString(),
                        });
                      }}
                      placeholder={t('common_select_placeholder')}
                      width={200}
                      mode='search'
                    />
                  </div>
                </div>
              );
            }}
          </BaseFilter>

          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('caregiver_button_title_add_applicant')}
            onClick={handleAddApplicant}
            width={130}
            height={36}
          />
        </ToolBar>
      </div>

      <div className={cx('baseTable')}>
        <BaseTable
          columns={columns({
            t,
            onView: handleViewDetail,
            onEdit: handleEdit,
            onSendEmail: handleSendEmail,
            onChangeStatus: handleShowChangeStatus,
            onChangeStage: handleChangeStage,
            onConvertToCaregiver: handleShowConvertToCaregiver,
          })}
          dataSource={applicantList}
          onClickRow={handleApplicantRowClick}
        />
      </div>

      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={pagination?.totalItems}
          totalPages={pagination?.totalPages}
        />
      </div>

      <ConfirmModal
        isOpen={isConvertToCaregiver}
        onAction={handleConvertToCaregiver}
        onCancel={handleCloseConvertToCaregiver}
        title={t('convert_to_caregiver_modal_text_title')}
        icon={icons.crmIconUserExchangeInformation}
        titleAction={t('convert_to_caregiver_modal_convert_button')}
      />

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderdevelopment} />}

      {isChangeStage && (
        <ChangeStageModal
          dataDefault={recordRequestApplicant?.stage?.id || EMPTY_STRING}
          isOpen={isChangeStage}
          onClose={handleCloseChangeStage}
          dataOption={listStage}
          onSubmit={handleSubmitChangeStage}
        />
      )}

      {isChangeStatus && (
        <ModalChangeStatus
          isOpen={isChangeStatus}
          dataDefault={recordRequestApplicant?.status || EMPTY_STRING}
          dataOption={optionStatusRequestApplicant}
          onClose={handleCloseChangeStatus}
          onSubmit={handleChangeStatus}
          isLoading={isLoadingChangeStatus}
        />
      )}

      {isShowSendEmailModal && (
        <SendEmailMultipleModal
          isOpen={isShowSendEmailModal}
          onClose={handleCloseSendEmail}
          onSendEmail={handleSendEmailApplicant}
          isLoading={isLoadingSendEmail}
          defaultEmails={recordRequestApplicant?.email ? [recordRequestApplicant?.email] : []}
        />
      )}
    </div>
  );
};

export default RequestApplicant;
