// Components, Layouts, Pages
import {
  AccountingPage,
  CrmPage,
  CaregiverPage,
  ManageEmployee,
  RequestApplicantPage,
  ReportingPage,
  TimeClockCaregiverPage,
  TimeClockEmployeePage,
  CareAlertsShowAll,
  CaregiverShowAll,
  CaregiverDetailPage,
  CaregiverReferralShowAll,
  MessagePage,
  SignIn,
  ForgotPassword,
  NewPasswordPage,
  ChangePasswordSuccessPage,
  VerifyCode,
  SchedulePageStaff,
  CrmPageStaff,
  CaregiverPageStaff,
  ReportingPageStaff,
  TaskPageStaff,
  FormPageStaff,
  DocumentPageStaff,
  TimeClockCaregiverPageStaff,
  TimeClockEmployeePageStaff,
  InvoicePageStaff,
  HrPageCaregiver,
  TimeClockPageCaregiver,
  CrmPageCaregiver,
  SchedulePageCaregiver,
  HomeStaff,
  HomeAdmin,
  HomeCaregiver,
  RequestApplicantStaff,
  CaregiverShowAllStaff,
  CareAlertsShowAllStaff,
  CaregiverReferralShowAllStaff,
  MessageStaff,
  TaskPage,
  TaskPageCaregiver,
  SignUp,
  SchedulePageAdmin,
  OfficeCalendarPage,
  OpenShiftsCalendarPage,
  DailyClientsPage,
  WeeklyClientsPage,
  WeeklyCaregiversPage,
  DailyCaregiversPage,
  DailyClientsPageStaff,
  DailyCaregiversPageStaff,
  WeeklyClientsPageStaff,
  WeeklyCaregiversPageStaff,
  OfficeCalendarPageStaff,
  OpenShiftsCalendarPageStaff,
  CrmClientDetailPage,
  CrmReferralSourceDetailPage,
  CrmProspectDetailPage,
  CrmClientAdminPage,
  CrmProspectAdminPage,
  CrmReferralSourceAdminPage,
  ChangePasswordAdminPage,
  ChangePasswordPageStaff,
  ChangePasswordPageCaregiver,
  SettingRingCentralAdminPage,
  SettingsAdminPage,
  InvoicePageAdmin,
  QuickBookCallBackTemplate,
  AddCaregiverPageAdmin,
  CrmAddClient,
  ReportingCaregiverPageAdmin,
  ReportingClientPageAdmin,
  CrmLocationAdminPage,
  TimeClockEmployeeDetailPageAdmin,
  TimeClockCaregiverDetailPageAdmin,
  CrmLocationDetailAdminPage,
  FormEmployeesAdminPage,
  CrmContactsAdminPage,
  CrmInitialCallAdminPage,
  CrmAddReferralSource,
  CrmAddProspect,
  FormInitialCallAdminPage,
  FormContactAdminPage,
  CrmContactDetailAdminPage,
  InitialCallDetailAdminPage,
  FormRequestApplicantAdminPage,
  FormRequestApplicantStaffPage,
  CaregiverReferralFormAdmin,
  CaregiverReferralFormStaff,
  CrmCampaignAdminPage,
  RequestApplicantDetailAdminPage,
  RequestApplicantDetailStaffPage,
  CaregiverReferralDetailsAdminPage,
  InvoiceDetailAdminPage,
  TimeClockPage,
  TimeClockPageStaff,
  TimeClockEmployeeDetailPageStaff,
  TimeClockCaregiverDetailPageStaff,
  HrPortalTemplate,
  HrEmployeeAdminPage,
  HrCaregiverAdminPage,
  HrEmployeeDetailAdminPage,
  HrCaregiverDetailAdminPage,
  HrCaregiverStaffPage,
  HrCaregiverDetailStaffPage,
  ReportInvoiceAdminPage,
  ReportInvoiceStaffPage,
  EmployeeDetailStaffPage,
  EmployeeDetailAdminPage,
} from '~/pages';
// Others
import { IRouteModel } from '../interface/common';
import { MenuNameTransKeyEnum } from '~/utils/enum';

// Router && Path
export const publicRoute = {
  base: '/',
  auth: 'auth',
  forgotPassword: '/forgot-password',
  verifyCode: '/verify-code',
  newPassword: '/new-password',
  changePasswordSuccess: '/change-password-success',
  signUp: '/sign-up',
  quickBookCallback: '/quick-book/call-back',
};

export const superAdminRoute = {
  base: '/super-admin',
  home: '/home',
  schedule: '/schedule',
  employees: '/employees',
  hr: '/hr',
  hrEmployee: '/employee',
  hrCaregiver: '/caregiver',
  hrDetail: '/:hrId',
  crm: '/crm',
  crmClient: '/client',
  addClient: '/create',
  editClient: '/edit',
  crmProspect: '/prospect',
  addProspect: '/create',
  editProspect: '/edit',
  prospectId: ':prospectId',
  crmReferralSource: '/referral-source',
  addReferralSource: '/create',
  editReferralSource: '/edit',
  crmLocation: '/location',
  crmCampaign: '/campaign',
  crmContact: '/contact',
  crmInitialCall: '/initial-call',
  task: '/task',
  timeClock: '/time-clock',
  timeClockEmployee: '/employee',
  timeClockCaregiver: '/caregiver',
  reporting: '/reporting',
  reportingCaregiver: '/caregiver',
  reportingClient: '/client',
  accounting: '/accounting',
  caregiver: '/caregiver',
  addCaregiver: '/create',
  editCaregiver: '/edit',
  caregiverId: ':caregiverId',
  caregiverDetail: ':caregiverId',
  requestApplicant: 'request-applicant',
  createRequestApplicant: '/create',
  editRequestApplicant: '/edit',
  requestApplicantId: '/:requestApplicantId',
  careAlertsShowAll: 'care-alerts',
  caregiverShowAll: 'caregivers',
  caregiverReferralShowAll: 'caregiver-referral',
  referralId: ':referralId',
  messages: '/messages',
  conversationId: ':conversationId',
  clientId: ':clientId',
  officeCalendar: '/office-calendar',
  openShiftsCalendar: '/open-shifts-calendar',
  dailyClients: '/daily-clients',
  dailyCaregivers: '/daily-caregivers',
  weeklyClients: '/weekly-clients',
  weeklyCaregivers: '/weekly-caregivers',
  changePassword: '/change-password',
  invoice: '/invoice',
  invoiceId: ':invoiceId',
  ringCentral: '/ring-central',
  settings: '/settings',
  timeClockDetail: '/:timeClockId',
  locationDetail: '/:locationId',
  addEmployees: '/create',
  editEmployees: '/edit',
  employeeId: '/:employeeId',
  employeeDetail: '/employee',
  createInitialCall: '/create',
  createContacts: '/create',
  editContacts: '/edit',
  contactId: '/:contactId',
  initialCallId: '/:initialCallId',
  addCaregiverReferral: '/create',
  editCaregiverReferral: '/edit',
  caregiverReferralId: '/:caregiverReferralId',
};

export const adminRoute = {
  base: '/admin',
  home: '/home',
  schedule: '/schedule',
  employees: '/employees',
  hr: '/hr',
  hrEmployee: '/employee',
  hrCaregiver: '/caregiver',
  hrDetail: '/:hrId',
  crm: '/crm',
  crmClient: '/client',
  addClient: '/create',
  editClient: '/edit',
  crmProspect: '/prospect',
  addProspect: '/create',
  editProspect: '/edit',
  prospectId: ':prospectId',
  crmReferralSource: '/referral-source',
  addReferralSource: '/create',
  editReferralSource: '/edit',
  crmLocation: '/location',
  crmCampaign: '/campaign',
  crmContact: '/contact',
  crmInitialCall: '/initial-call',
  task: '/task',
  timeClock: '/time-clock',
  timeClockEmployee: '/employee',
  timeClockCaregiver: '/caregiver',
  reporting: '/reporting',
  reportingCaregiver: '/caregiver',
  reportingClient: '/client',
  accounting: '/accounting',
  caregiver: '/caregiver',
  addCaregiver: '/create',
  editCaregiver: '/edit',
  caregiverId: ':caregiverId',
  caregiverDetail: ':caregiverId',
  requestApplicant: 'request-applicant',
  createRequestApplicant: '/create',
  editRequestApplicant: '/edit',
  requestApplicantId: '/:requestApplicantId',
  careAlertsShowAll: 'care-alerts',
  caregiverShowAll: 'caregivers',
  caregiverReferralShowAll: 'caregiver-referral',
  referralId: ':referralId',
  messages: '/messages',
  conversationId: ':conversationId',
  clientId: ':clientId',
  officeCalendar: '/office-calendar',
  openShiftsCalendar: '/open-shifts-calendar',
  dailyClients: '/daily-clients',
  dailyCaregivers: '/daily-caregivers',
  weeklyClients: '/weekly-clients',
  weeklyCaregivers: '/weekly-caregivers',
  changePassword: '/change-password',
  invoice: '/invoice',
  invoiceId: ':invoiceId',
  ringCentral: '/ring-central',
  settings: '/settings',
  timeClockDetail: '/:timeClockId',
  locationDetail: '/:locationId',
  addEmployees: '/create',
  editEmployees: '/edit',
  employeeId: '/:employeeId',
  employeeDetail: '/employee',
  createInitialCall: '/create',
  createContacts: '/create',
  editContacts: '/edit',
  contactId: '/:contactId',
  initialCallId: '/:initialCallId',
  addCaregiverReferral: '/create',
  editCaregiverReferral: '/edit',
  caregiverReferralId: '/:caregiverReferralId',
};

export const staffRoute = {
  base: '/staff',
  home: '/home',
  schedule: '/schedule',
  employees: '/employees',
  hr: '/hr',
  hrEmployee: '/employee',
  hrCaregiver: '/caregiver',
  hrDetail: '/:hrId',
  crm: '/crm',
  crmClient: '/client',
  addClient: '/create',
  editClient: '/edit',
  crmProspect: '/prospect',
  addProspect: '/create',
  editProspect: '/edit',
  prospectId: ':prospectId',
  crmReferralSource: '/referral-source',
  addReferralSource: '/create',
  editReferralSource: '/edit',
  crmLocation: '/location',
  crmCampaign: '/campaign',
  crmContact: '/contact',
  crmInitialCall: '/initial-call',
  task: '/task',
  timeClock: '/time-clock',
  timeClockEmployee: '/employee',
  timeClockCaregiver: '/caregiver',
  reporting: '/reporting',
  reportingCaregiver: '/caregiver',
  reportingClient: '/client',
  accounting: '/accounting',
  caregiver: '/caregiver',
  addCaregiver: '/create',
  editCaregiver: '/edit',
  caregiverId: ':caregiverId',
  caregiverDetail: ':caregiverId',
  requestApplicant: 'request-applicant',
  createRequestApplicant: '/create',
  editRequestApplicant: '/edit',
  requestApplicantId: '/:requestApplicantId',
  careAlertsShowAll: 'care-alerts',
  caregiverShowAll: 'caregivers',
  caregiverReferralShowAll: 'caregiver-referral',
  referralId: ':referralId',
  messages: '/messages',
  conversationId: ':conversationId',
  clientId: ':clientId',
  officeCalendar: '/office-calendar',
  openShiftsCalendar: '/open-shifts-calendar',
  dailyClients: '/daily-clients',
  dailyCaregivers: '/daily-caregivers',
  weeklyClients: '/weekly-clients',
  weeklyCaregivers: '/weekly-caregivers',
  changePassword: '/change-password',
  invoice: '/invoice',
  invoiceId: ':invoiceId',
  // ringCentral: '/ring-central',
  settings: '/settings',
  timeClockDetail: '/:timeClockId',
  locationDetail: '/:locationId',
  addEmployees: '/create',
  editEmployees: '/edit',
  employeeId: '/:employeeId',
  employeeDetail: '/employee',
  createInitialCall: '/create',
  createContacts: '/create',
  editContacts: '/edit',
  contactId: '/:contactId',
  initialCallId: '/:initialCallId',
  addCaregiverReferral: '/create',
  editCaregiverReferral: '/edit',
  caregiverReferralId: '/:caregiverReferralId',
};

export const caregiverRoute = {
  base: '/caregiver',
  home: '/home',
  schedule: '/schedule',
  crm: '/crm',
  crmClient: '/client',
  crmProspect: '/prospect',
  crmReferralSource: '/referral-source',
  clientId: ':clientId',
  timeClock: '/time-clock',
  hr: '/hr',
  task: '/task',
  changePassword: '/change-password',
};

//#region Auth Routes
export const authRoutes: IRouteModel[] = [
  {
    path: `${publicRoute.base}${publicRoute.auth}`,
    component: SignIn,
  },
  {
    path: `${publicRoute.base}${publicRoute.auth}${publicRoute.forgotPassword}`,
    component: ForgotPassword,
  },
  {
    path: `${publicRoute.base}${publicRoute.auth}${publicRoute.newPassword}`,
    component: NewPasswordPage,
  },
  {
    path: `${publicRoute.base}${publicRoute.auth}${publicRoute.changePasswordSuccess}`,
    component: ChangePasswordSuccessPage,
  },
  {
    path: `${publicRoute.base}${publicRoute.auth}${publicRoute.signUp}`,
    component: SignUp,
  },
  {
    path: `${publicRoute.base}${publicRoute.auth}${publicRoute.verifyCode}`,
    component: VerifyCode,
  },
];
//#endregion Auth Routes

//#region Public Routes
export const publicRoutes: IRouteModel[] = [
  {
    path: `${publicRoute.quickBookCallback}`,
    component: QuickBookCallBackTemplate,
  },
];
//#endregion Public Routes

//#region Private Routes
export const privateSuperAdminRoutes: IRouteModel[] = [
  {
    index: true,
    name: MenuNameTransKeyEnum.HOME,
    path: superAdminRoute.base,
    component: HomeAdmin,
  },
  {
    name: MenuNameTransKeyEnum.TIME_CLOCK,
    path: `${superAdminRoute.base}${superAdminRoute.timeClock}`,
    component: TimeClockPage,
    children: [
      {
        name: MenuNameTransKeyEnum.TIME_CLOCK_EMPLOYEE,
        path: `${superAdminRoute.base}${superAdminRoute.timeClock}${superAdminRoute.timeClockEmployee}`,
        component: TimeClockEmployeePage,
      },
      {
        name: MenuNameTransKeyEnum.TIME_CLOCK_CAREGIVER,
        path: `${superAdminRoute.base}${superAdminRoute.timeClock}${superAdminRoute.timeClockCaregiver}`,
        component: TimeClockCaregiverPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.TASK,
    path: `${superAdminRoute.base}${superAdminRoute.task}`,
    component: TaskPage,
  },
  {
    name: MenuNameTransKeyEnum.SCHEDULE,
    path: `${superAdminRoute.base}${superAdminRoute.schedule}`,
    component: SchedulePageAdmin,
    children: [
      {
        index: true,
        name: MenuNameTransKeyEnum.LIVE_VIEW,
        path: `${superAdminRoute.base}${superAdminRoute.schedule}`,
        component: SchedulePageAdmin,
        children: [
          {
            index: true,
            name: MenuNameTransKeyEnum.DAILY_CLIENTS,
            path: `${superAdminRoute.base}${superAdminRoute.schedule}${superAdminRoute.dailyClients}`,
            component: DailyClientsPage,
          },
          {
            name: MenuNameTransKeyEnum.DAILY_CAREGIVERS,
            path: `${superAdminRoute.base}${superAdminRoute.schedule}${superAdminRoute.dailyCaregivers}`,
            component: DailyCaregiversPage,
          },
          {
            name: MenuNameTransKeyEnum.WEEKLY_CLIENTS,
            path: `${superAdminRoute.base}${superAdminRoute.schedule}${superAdminRoute.weeklyClients}`,
            component: WeeklyClientsPage,
          },
          {
            name: MenuNameTransKeyEnum.WEEKLY_CAREGIVERS,
            path: `${superAdminRoute.base}${superAdminRoute.schedule}${superAdminRoute.weeklyCaregivers}`,
            component: WeeklyCaregiversPage,
          },
        ],
      },
      {
        name: MenuNameTransKeyEnum.OFFICE_CALENDAR,
        path: `${superAdminRoute.base}${superAdminRoute.schedule}${superAdminRoute.officeCalendar}`,
        component: OfficeCalendarPage,
      },
      {
        name: MenuNameTransKeyEnum.OPEN_SHIFT_CALENDAR,
        path: `${superAdminRoute.base}${superAdminRoute.schedule}${superAdminRoute.openShiftsCalendar}`,
        component: OpenShiftsCalendarPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.EMPLOYEES,
    path: `${superAdminRoute.base}${superAdminRoute.employees}`,
    component: ManageEmployee,
  },
  {
    name: MenuNameTransKeyEnum.HR,
    path: `${superAdminRoute.base}${superAdminRoute.hr}`,
    component: HrPortalTemplate,
    children: [
      {
        name: MenuNameTransKeyEnum.HR_EMPLOYEE,
        path: `${superAdminRoute.base}${superAdminRoute.hr}${superAdminRoute.hrEmployee}`,
        component: HrEmployeeAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.HR_CAREGIVER,
        path: `${superAdminRoute.base}${superAdminRoute.hr}${superAdminRoute.hrCaregiver}`,
        component: HrCaregiverAdminPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.CAREGIVERS,
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}`,
    component: CaregiverPage,
    children: [
      {
        name: MenuNameTransKeyEnum.CAREGIVER_REQUEST_APPLICANT,
        path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.requestApplicant}`,
        component: RequestApplicantPage,
      },
      {
        name: MenuNameTransKeyEnum.CAREGIVER,
        path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverShowAll}`,
        component: CaregiverShowAll,
      },
      {
        name: MenuNameTransKeyEnum.CAREGIVER_REFERRAL,
        path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverReferralShowAll}`,
        component: CaregiverReferralShowAll,
      },
    ],
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverShowAll}${superAdminRoute.addCaregiver}`,
    component: AddCaregiverPageAdmin,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverShowAll}${superAdminRoute.editCaregiver}/${superAdminRoute.caregiverId}`,
    component: AddCaregiverPageAdmin,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverShowAll}/${superAdminRoute.caregiverDetail}`,
    component: CaregiverDetailPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverReferralShowAll}${superAdminRoute.addCaregiverReferral}`,
    component: CaregiverReferralFormAdmin,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverReferralShowAll}${superAdminRoute.editCaregiverReferral}${superAdminRoute.caregiverReferralId}`,
    component: CaregiverReferralFormAdmin,
  },
  {
    name: MenuNameTransKeyEnum.CLIENT,
    path: `${superAdminRoute.base}${superAdminRoute.crmClient}`,
    component: CrmClientAdminPage,
  },
  {
    name: MenuNameTransKeyEnum.CRM,
    path: `${superAdminRoute.base}${superAdminRoute.crm}`,
    component: CrmPage,
    children: [
      {
        name: MenuNameTransKeyEnum.INITIAL_CALL,
        path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmInitialCall}`,
        component: CrmInitialCallAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.PROSPECT,
        path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmProspect}`,
        component: CrmProspectAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.REFERRAL_SOURCE,
        path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmReferralSource}`,
        component: CrmReferralSourceAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.CONTACT,
        path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmContact}`,
        component: CrmContactsAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.LOCATION,
        path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmLocation}`,
        component: CrmLocationAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.CAMPAIGN,
        path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmCampaign}`,
        component: CrmCampaignAdminPage,
      },
    ],
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmLocation}${superAdminRoute.locationDetail}`,
    component: CrmLocationDetailAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crmClient}${superAdminRoute.addClient}`,
    component: CrmAddClient,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crmClient}${superAdminRoute.editClient}/${superAdminRoute.clientId}`,
    component: CrmAddClient,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmReferralSource}${superAdminRoute.addReferralSource}`,
    component: CrmAddReferralSource,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmReferralSource}${superAdminRoute.editReferralSource}/${superAdminRoute.clientId}`,
    component: CrmAddReferralSource,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmProspect}${superAdminRoute.addProspect}`,
    component: CrmAddProspect,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmProspect}${superAdminRoute.editProspect}/${superAdminRoute.prospectId}`,
    component: CrmAddProspect,
  },
  {
    name: MenuNameTransKeyEnum.REPORTING,
    path: `${superAdminRoute.base}${superAdminRoute.reporting}`,
    component: ReportingPage,
    children: [
      {
        name: MenuNameTransKeyEnum.REPORTING_CAREGIVER,
        path: `${superAdminRoute.base}${superAdminRoute.reporting}${superAdminRoute.reportingCaregiver}`,
        component: ReportingCaregiverPageAdmin,
      },
      {
        name: MenuNameTransKeyEnum.REPORTING_CLIENT,
        path: `${superAdminRoute.base}${superAdminRoute.reporting}${superAdminRoute.reportingClient}`,
        component: ReportingClientPageAdmin,
      },
      {
        name: MenuNameTransKeyEnum.REPORTING_INVOICE,
        path: `${superAdminRoute.base}${superAdminRoute.reporting}${superAdminRoute.invoice}`,
        component: ReportInvoiceAdminPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.ACCOUNTING,
    path: `${superAdminRoute.base}${superAdminRoute.accounting}`,
    component: AccountingPage,
    children: [
      {
        name: MenuNameTransKeyEnum.INVOICE,
        path: `${superAdminRoute.base}${superAdminRoute.accounting}${superAdminRoute.invoice}`,
        component: InvoicePageAdmin,
      },
    ],
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crmClient}/${superAdminRoute.clientId}`,
    component: CrmClientDetailPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmProspect}/${superAdminRoute.clientId}`,
    component: CrmProspectDetailPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmReferralSource}/${superAdminRoute.clientId}`,
    component: CrmReferralSourceDetailPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.messages}/${superAdminRoute.conversationId}`,
    component: MessagePage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.requestApplicant}`,
    component: RequestApplicantPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.careAlertsShowAll}`,
    component: CareAlertsShowAll,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverShowAll}`,
    component: CaregiverShowAll,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverReferralShowAll}`,
    component: CaregiverReferralShowAll,
  },
  {
    path: `${superAdminRoute.base}/${superAdminRoute.careAlertsShowAll}`,
    component: CareAlertsShowAll,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.messages}`,
    component: MessagePage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.changePassword}`,
    component: ChangePasswordAdminPage,
  },
  // {
  //   path: `${superAdminRoute.base}${superAdminRoute.ringCentral}`,
  //   component: SettingRingCentralAdminPage,
  // },
  {
    path: `${superAdminRoute.base}${superAdminRoute.settings}`,
    component: SettingsAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.timeClock}${superAdminRoute.timeClockEmployee}${superAdminRoute.timeClockDetail}`,
    component: TimeClockEmployeeDetailPageAdmin,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.timeClock}${superAdminRoute.timeClockCaregiver}${superAdminRoute.timeClockDetail}`,
    component: TimeClockCaregiverDetailPageAdmin,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.employees}${superAdminRoute.addEmployees}`,
    component: FormEmployeesAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.employees}${superAdminRoute.employeeDetail}${superAdminRoute.employeeId}`,
    component: EmployeeDetailAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.employees}${superAdminRoute.editClient}${superAdminRoute.employeeId}`,
    component: FormEmployeesAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmInitialCall}${superAdminRoute.createInitialCall}`,
    component: FormInitialCallAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmInitialCall}${superAdminRoute.initialCallId}`,
    component: InitialCallDetailAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmContact}${superAdminRoute.createContacts}`,
    component: FormContactAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmContact}${superAdminRoute.editContacts}${superAdminRoute.contactId}`,
    component: FormContactAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmContact}${superAdminRoute.contactId}`,
    component: CrmContactDetailAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.requestApplicant}${superAdminRoute.createRequestApplicant}`,
    component: FormRequestApplicantAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.requestApplicant}${superAdminRoute.editCaregiver}${superAdminRoute.requestApplicantId}`,
    component: FormRequestApplicantAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverReferralShowAll}/${superAdminRoute.referralId}`,
    component: CaregiverReferralDetailsAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.requestApplicant}${superAdminRoute.requestApplicantId}`,
    component: RequestApplicantDetailAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.accounting}${superAdminRoute.invoice}/${superAdminRoute.invoiceId}`,
    component: InvoiceDetailAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.hr}${superAdminRoute.hrEmployee}${superAdminRoute.hrDetail}`,
    component: HrEmployeeDetailAdminPage,
  },
  {
    path: `${superAdminRoute.base}${superAdminRoute.hr}${superAdminRoute.hrCaregiver}${superAdminRoute.hrDetail}`,
    component: HrCaregiverDetailAdminPage,
  },
];

export const privateAdminRoutes: IRouteModel[] = [
  {
    index: true,
    name: MenuNameTransKeyEnum.HOME,
    path: adminRoute.base,
    component: HomeAdmin,
  },
  {
    name: MenuNameTransKeyEnum.TIME_CLOCK,
    path: `${adminRoute.base}${adminRoute.timeClock}`,
    component: TimeClockPage,
    children: [
      {
        name: MenuNameTransKeyEnum.TIME_CLOCK_EMPLOYEE,
        path: `${adminRoute.base}${adminRoute.timeClock}${adminRoute.timeClockEmployee}`,
        component: TimeClockEmployeePage,
      },
      {
        name: MenuNameTransKeyEnum.TIME_CLOCK_CAREGIVER,
        path: `${adminRoute.base}${adminRoute.timeClock}${adminRoute.timeClockCaregiver}`,
        component: TimeClockCaregiverPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.TASK,
    path: `${adminRoute.base}${adminRoute.task}`,
    component: TaskPage,
  },
  {
    name: MenuNameTransKeyEnum.SCHEDULE,
    path: `${adminRoute.base}${adminRoute.schedule}`,
    component: SchedulePageAdmin,
    children: [
      {
        index: true,
        name: MenuNameTransKeyEnum.LIVE_VIEW,
        path: `${adminRoute.base}${adminRoute.schedule}`,
        component: SchedulePageAdmin,
        children: [
          {
            index: true,
            name: MenuNameTransKeyEnum.DAILY_CLIENTS,
            path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.dailyClients}`,
            component: DailyClientsPage,
          },
          {
            name: MenuNameTransKeyEnum.DAILY_CAREGIVERS,
            path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.dailyCaregivers}`,
            component: DailyCaregiversPage,
          },
          {
            name: MenuNameTransKeyEnum.WEEKLY_CLIENTS,
            path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.weeklyClients}`,
            component: WeeklyClientsPage,
          },
          {
            name: MenuNameTransKeyEnum.WEEKLY_CAREGIVERS,
            path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.weeklyCaregivers}`,
            component: WeeklyCaregiversPage,
          },
        ],
      },
      {
        name: MenuNameTransKeyEnum.OFFICE_CALENDAR,
        path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.officeCalendar}`,
        component: OfficeCalendarPage,
      },
      {
        name: MenuNameTransKeyEnum.OPEN_SHIFT_CALENDAR,
        path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.openShiftsCalendar}`,
        component: OpenShiftsCalendarPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.EMPLOYEES,
    path: `${adminRoute.base}${adminRoute.employees}`,
    component: ManageEmployee,
  },
  {
    name: MenuNameTransKeyEnum.HR,
    path: `${adminRoute.base}${adminRoute.hr}`,
    component: HrPortalTemplate,
    children: [
      {
        name: MenuNameTransKeyEnum.HR_EMPLOYEE,
        path: `${adminRoute.base}${adminRoute.hr}${adminRoute.hrEmployee}`,
        component: HrEmployeeAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.HR_CAREGIVER,
        path: `${adminRoute.base}${adminRoute.hr}${adminRoute.hrCaregiver}`,
        component: HrCaregiverAdminPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.CAREGIVERS,
    path: `${adminRoute.base}${adminRoute.caregiver}`,
    component: CaregiverPage,
    children: [
      {
        name: MenuNameTransKeyEnum.CAREGIVER_REQUEST_APPLICANT,
        path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}`,
        component: RequestApplicantPage,
      },
      {
        name: MenuNameTransKeyEnum.CAREGIVER,
        path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}`,
        component: CaregiverShowAll,
      },
      {
        name: MenuNameTransKeyEnum.CAREGIVER_REFERRAL,
        path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}`,
        component: CaregiverReferralShowAll,
      },
    ],
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}${adminRoute.addCaregiver}`,
    component: AddCaregiverPageAdmin,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}${adminRoute.editCaregiver}/${adminRoute.caregiverId}`,
    component: AddCaregiverPageAdmin,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}/${adminRoute.caregiverDetail}`,
    component: CaregiverDetailPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}${adminRoute.addCaregiverReferral}`,
    component: CaregiverReferralFormAdmin,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}${adminRoute.editCaregiverReferral}${adminRoute.caregiverReferralId}`,
    component: CaregiverReferralFormAdmin,
  },
  {
    name: MenuNameTransKeyEnum.CLIENT,
    path: `${adminRoute.base}${adminRoute.crmClient}`,
    component: CrmClientAdminPage,
  },
  {
    name: MenuNameTransKeyEnum.CRM,
    path: `${adminRoute.base}${adminRoute.crm}`,
    component: CrmPage,
    children: [
      {
        name: MenuNameTransKeyEnum.INITIAL_CALL,
        path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmInitialCall}`,
        component: CrmInitialCallAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.PROSPECT,
        path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmProspect}`,
        component: CrmProspectAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.REFERRAL_SOURCE,
        path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmReferralSource}`,
        component: CrmReferralSourceAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.CONTACT,
        path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmContact}`,
        component: CrmContactsAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.LOCATION,
        path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmLocation}`,
        component: CrmLocationAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.CAMPAIGN,
        path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmCampaign}`,
        component: CrmCampaignAdminPage,
      },
    ],
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmLocation}${adminRoute.locationDetail}`,
    component: CrmLocationDetailAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.crmClient}${adminRoute.addClient}`,
    component: CrmAddClient,
  },
  {
    path: `${adminRoute.base}${adminRoute.crmClient}${adminRoute.editClient}/${adminRoute.clientId}`,
    component: CrmAddClient,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmProspect}${adminRoute.addProspect}`,
    component: CrmAddProspect,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmProspect}${adminRoute.editProspect}/${adminRoute.prospectId}`,
    component: CrmAddProspect,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmReferralSource}${adminRoute.addReferralSource}`,
    component: CrmAddReferralSource,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmReferralSource}${adminRoute.editReferralSource}/${adminRoute.clientId}`,
    component: CrmAddReferralSource,
  },
  {
    name: MenuNameTransKeyEnum.REPORTING,
    path: `${adminRoute.base}${adminRoute.reporting}`,
    component: ReportingPage,
    children: [
      {
        name: MenuNameTransKeyEnum.REPORTING_CAREGIVER,
        path: `${adminRoute.base}${adminRoute.reporting}${adminRoute.reportingCaregiver}`,
        component: ReportingCaregiverPageAdmin,
      },
      {
        name: MenuNameTransKeyEnum.REPORTING_CLIENT,
        path: `${adminRoute.base}${adminRoute.reporting}${adminRoute.reportingClient}`,
        component: ReportingClientPageAdmin,
      },
      {
        name: MenuNameTransKeyEnum.REPORTING_INVOICE,
        path: `${adminRoute.base}${adminRoute.reporting}${adminRoute.invoice}`,
        component: ReportInvoiceAdminPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.ACCOUNTING,
    path: `${adminRoute.base}${adminRoute.accounting}`,
    component: AccountingPage,
    children: [
      {
        index: true,
        name: MenuNameTransKeyEnum.INVOICE,
        path: `${adminRoute.base}${adminRoute.accounting}${adminRoute.invoice}`,
        component: InvoicePageAdmin,
      },
    ],
  },
  {
    path: `${adminRoute.base}${adminRoute.crmClient}/${adminRoute.clientId}`,
    component: CrmClientDetailPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmProspect}/${adminRoute.clientId}`,
    component: CrmProspectDetailPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmReferralSource}/${adminRoute.clientId}`,
    component: CrmReferralSourceDetailPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.messages}/${adminRoute.conversationId}`,
    component: MessagePage,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}`,
    component: RequestApplicantPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.careAlertsShowAll}`,
    component: CareAlertsShowAll,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}`,
    component: CaregiverShowAll,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}`,
    component: CaregiverReferralShowAll,
  },
  {
    path: `${adminRoute.base}/${adminRoute.careAlertsShowAll}`,
    component: CareAlertsShowAll,
  },
  {
    path: `${adminRoute.base}${adminRoute.messages}`,
    component: MessagePage,
  },
  {
    path: `${adminRoute.base}${adminRoute.changePassword}`,
    component: ChangePasswordAdminPage,
  },
  // {
  //   path: `${adminRoute.base}${adminRoute.ringCentral}`,
  //   component: SettingRingCentralAdminPage,
  // },
  {
    path: `${adminRoute.base}${adminRoute.settings}`,
    component: SettingsAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.timeClock}${adminRoute.timeClockEmployee}${adminRoute.timeClockDetail}`,
    component: TimeClockEmployeeDetailPageAdmin,
  },
  {
    path: `${adminRoute.base}${adminRoute.timeClock}${adminRoute.timeClockCaregiver}${adminRoute.timeClockDetail}`,
    component: TimeClockCaregiverDetailPageAdmin,
  },
  {
    path: `${adminRoute.base}${adminRoute.employees}${adminRoute.addEmployees}`,
    component: FormEmployeesAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.employees}${adminRoute.editClient}${adminRoute.employeeId}`,
    component: FormEmployeesAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.employees}${adminRoute.employeeDetail}${adminRoute.employeeId}`,
    component: EmployeeDetailAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmInitialCall}${adminRoute.createInitialCall}`,
    component: FormInitialCallAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmInitialCall}${adminRoute.initialCallId}`,
    component: InitialCallDetailAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmContact}${adminRoute.createContacts}`,
    component: FormContactAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmContact}${adminRoute.editContacts}${adminRoute.contactId}`,
    component: FormContactAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmContact}${adminRoute.contactId}`,
    component: CrmContactDetailAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}${adminRoute.createRequestApplicant}`,
    component: FormRequestApplicantAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}${adminRoute.editCaregiver}${adminRoute.requestApplicantId}`,
    component: FormRequestApplicantAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}${adminRoute.requestApplicantId}`,
    component: RequestApplicantDetailAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}/${adminRoute.referralId}`,
    component: CaregiverReferralDetailsAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.accounting}${adminRoute.invoice}/${adminRoute.invoiceId}`,
    component: InvoiceDetailAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.hr}${adminRoute.hrEmployee}${adminRoute.hrDetail}`,
    component: HrEmployeeDetailAdminPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.hr}${adminRoute.hrCaregiver}${adminRoute.hrDetail}`,
    component: HrCaregiverDetailAdminPage,
  },
];

export const privateStaffRoutes: IRouteModel[] = [
  {
    index: true,
    name: MenuNameTransKeyEnum.HOME,
    path: staffRoute.base,
    component: HomeStaff,
  },
  {
    name: MenuNameTransKeyEnum.TIME_CLOCK,
    path: `${staffRoute.base}${staffRoute.timeClock}`,
    component: TimeClockPageStaff,
    children: [
      {
        name: MenuNameTransKeyEnum.TIME_CLOCK_EMPLOYEE,
        path: `${staffRoute.base}${staffRoute.timeClock}${staffRoute.timeClockEmployee}`,
        component: TimeClockEmployeePageStaff,
      },
      {
        name: MenuNameTransKeyEnum.TIME_CLOCK_CAREGIVER,
        path: `${staffRoute.base}${staffRoute.timeClock}${staffRoute.timeClockCaregiver}`,
        component: TimeClockCaregiverPageStaff,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.TASK,
    path: `${staffRoute.base}${staffRoute.task}`,
    component: TaskPageStaff,
  },
  {
    name: MenuNameTransKeyEnum.SCHEDULE,
    path: `${staffRoute.base}${staffRoute.schedule}`,
    component: SchedulePageStaff,
    children: [
      {
        index: true,
        name: MenuNameTransKeyEnum.LIVE_VIEW,
        path: `${staffRoute.base}${staffRoute.schedule}`,
        component: SchedulePageStaff,
        children: [
          {
            index: true,
            name: MenuNameTransKeyEnum.DAILY_CLIENTS,
            path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.dailyClients}`,
            component: DailyClientsPageStaff,
          },
          {
            name: MenuNameTransKeyEnum.DAILY_CAREGIVERS,
            path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.dailyCaregivers}`,
            component: DailyCaregiversPageStaff,
          },
          {
            name: MenuNameTransKeyEnum.WEEKLY_CLIENTS,
            path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.weeklyClients}`,
            component: WeeklyClientsPageStaff,
          },
          {
            name: MenuNameTransKeyEnum.WEEKLY_CAREGIVERS,
            path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.weeklyCaregivers}`,
            component: WeeklyCaregiversPageStaff,
          },
        ],
      },
      {
        name: MenuNameTransKeyEnum.OFFICE_CALENDAR,
        path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.officeCalendar}`,
        component: OfficeCalendarPageStaff,
      },
      {
        name: MenuNameTransKeyEnum.OPEN_SHIFT_CALENDAR,
        path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.openShiftsCalendar}`,
        component: OpenShiftsCalendarPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.EMPLOYEES,
    path: `${staffRoute.base}${staffRoute.employees}`,
    component: ManageEmployee,
  },
  {
    path: `${staffRoute.base}${staffRoute.employees}${staffRoute.addEmployees}`,
    component: FormEmployeesAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.employees}${staffRoute.editEmployees}${adminRoute.employeeId}`,
    component: FormEmployeesAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.employees}${staffRoute.employeeDetail}${staffRoute.employeeId}`,
    component: EmployeeDetailStaffPage,
  },
  // {
  //   name: MenuNameTransKeyEnum.CRM,
  //   path: `${staffRoute.base}${staffRoute.crm}`,
  //   component: CrmPageStaff,
  // },
  {
    name: MenuNameTransKeyEnum.HR,
    path: `${staffRoute.base}${staffRoute.hr}`,
    component: HrCaregiverStaffPage,
  },
  {
    name: MenuNameTransKeyEnum.CAREGIVERS,
    path: `${staffRoute.base}${staffRoute.caregiver}`,
    component: CaregiverPageStaff,
    children: [
      {
        name: MenuNameTransKeyEnum.CAREGIVER_REQUEST_APPLICANT,
        path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}`,
        component: RequestApplicantStaff,
      },
      {
        name: MenuNameTransKeyEnum.CAREGIVER,
        path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}`,
        component: CaregiverShowAllStaff,
      },
      {
        name: MenuNameTransKeyEnum.CAREGIVER_REFERRAL,
        path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}`,
        component: CaregiverReferralShowAllStaff,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.CLIENT,
    path: `${staffRoute.base}${staffRoute.crmClient}`,
    component: CrmClientAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}/${staffRoute.caregiverDetail}`,
    component: CaregiverDetailPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}${staffRoute.addCaregiver}`,
    component: AddCaregiverPageAdmin,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}${staffRoute.editCaregiver}/${staffRoute.caregiverId}`,
    component: AddCaregiverPageAdmin,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}${staffRoute.addCaregiverReferral}`,
    component: CaregiverReferralFormStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}${staffRoute.editCaregiverReferral}${staffRoute.caregiverReferralId}`,
    component: CaregiverReferralFormStaff,
  },
  {
    name: MenuNameTransKeyEnum.CRM,
    path: `${staffRoute.base}${staffRoute.crm}`,
    component: CrmPage,
    children: [
      {
        name: MenuNameTransKeyEnum.INITIAL_CALL,
        path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmInitialCall}`,
        component: CrmInitialCallAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.PROSPECT,
        path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmProspect}`,
        component: CrmProspectAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.REFERRAL_SOURCE,
        path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmReferralSource}`,
        component: CrmReferralSourceAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.CONTACT,
        path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmContact}`,
        component: CrmContactsAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.LOCATION,
        path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmLocation}`,
        component: CrmLocationAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.CAMPAIGN,
        path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmCampaign}`,
        component: CrmCampaignAdminPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.REPORTING,
    path: `${staffRoute.base}${staffRoute.reporting}`,
    component: ReportingPageStaff,
    children: [
      {
        name: MenuNameTransKeyEnum.REPORTING_CAREGIVER,
        path: `${staffRoute.base}${staffRoute.reporting}${staffRoute.reportingCaregiver}`,
        component: ReportingCaregiverPageAdmin,
      },
      {
        name: MenuNameTransKeyEnum.REPORTING_CLIENT,
        path: `${staffRoute.base}${staffRoute.reporting}${staffRoute.reportingClient}`,
        component: ReportingClientPageAdmin,
      },
      {
        name: MenuNameTransKeyEnum.REPORTING_INVOICE,
        path: `${staffRoute.base}${staffRoute.reporting}${staffRoute.invoice}`,
        component: ReportInvoiceStaffPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.ACCOUNTING,
    path: `${staffRoute.base}${staffRoute.accounting}`,
    component: AccountingPage,
    children: [
      {
        index: true,
        name: MenuNameTransKeyEnum.INVOICE,
        path: `${staffRoute.base}${staffRoute.accounting}${staffRoute.invoice}`,
        component: InvoicePageAdmin,
      },
    ],
  },

  // {
  //   name: MenuNameTransKeyEnum.FORM,
  //   path: `${staffRoute.base}${staffRoute.form}`,
  //   component: FormPageStaff,
  // },
  // {
  //   name: MenuNameTransKeyEnum.DOCUMENT,
  //   path: `${staffRoute.base}${staffRoute.document}`,
  //   component: DocumentPageStaff,
  // },
  // {
  //   name: MenuNameTransKeyEnum.INVOICE,
  //   path: `${staffRoute.base}${staffRoute.invoice}`,
  //   component: InvoicePageStaff,
  // },
  {
    path: `${staffRoute.base}${staffRoute.settings}`,
    component: SettingsAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmInitialCall}${staffRoute.initialCallId}`,
    component: InitialCallDetailAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmInitialCall}${staffRoute.createInitialCall}`,
    component: FormInitialCallAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmProspect}${staffRoute.addProspect}`,
    component: CrmAddProspect,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmProspect}${staffRoute.editProspect}/${staffRoute.prospectId}`,
    component: CrmAddProspect,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmContact}${staffRoute.createContacts}`,
    component: FormContactAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmContact}${staffRoute.editContacts}${staffRoute.contactId}`,
    component: FormContactAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmContact}${staffRoute.contactId}`,
    component: CrmContactDetailAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}`,
    component: RequestApplicantStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmLocation}${staffRoute.locationDetail}`,
    component: CrmLocationDetailAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}`,
    component: CaregiverShowAllStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.careAlertsShowAll}`,
    component: CareAlertsShowAllStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}`,
    component: CaregiverReferralShowAllStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.messages}/${staffRoute.conversationId}`,
    component: MessageStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.crmClient}/${staffRoute.clientId}`,
    component: CrmClientDetailPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmProspect}/${staffRoute.clientId}`,
    component: CrmProspectDetailPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmReferralSource}${staffRoute.addReferralSource}`,
    component: CrmAddReferralSource,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmReferralSource}${staffRoute.editReferralSource}/${staffRoute.clientId}`,
    component: CrmAddReferralSource,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmReferralSource}/${staffRoute.clientId}`,
    component: CrmReferralSourceDetailPage,
  },
  {
    path: `${staffRoute.base}/${staffRoute.careAlertsShowAll}`,
    component: CareAlertsShowAllStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.messages}`,
    component: MessageStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.changePassword}`,
    component: ChangePasswordPageStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}${staffRoute.createRequestApplicant}`,
    component: FormRequestApplicantStaffPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}${staffRoute.editCaregiver}${staffRoute.requestApplicantId}`,
    component: FormRequestApplicantStaffPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}${staffRoute.requestApplicantId}`,
    component: RequestApplicantDetailStaffPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}/${staffRoute.referralId}`,
    component: CaregiverReferralDetailsAdminPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.timeClock}${staffRoute.timeClockEmployee}${staffRoute.timeClockDetail}`,
    component: TimeClockEmployeeDetailPageStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.timeClock}${staffRoute.timeClockCaregiver}${staffRoute.timeClockDetail}`,
    component: TimeClockCaregiverDetailPageStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.hr}${staffRoute.hrDetail}`,
    component: HrCaregiverDetailStaffPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.crmClient}${staffRoute.addClient}`,
    component: CrmAddClient,
  },
  {
    path: `${staffRoute.base}${staffRoute.crmClient}${staffRoute.editClient}/${staffRoute.clientId}`,
    component: CrmAddClient,
  },
  {
    path: `${staffRoute.base}${staffRoute.accounting}${staffRoute.invoice}/${staffRoute.invoiceId}`,
    component: InvoiceDetailAdminPage,
  },
];

export const privateCaregiverRoutes: IRouteModel[] = [
  {
    index: true,
    name: MenuNameTransKeyEnum.HOME,
    path: caregiverRoute.base,
    component: HomeCaregiver,
  },
  {
    name: MenuNameTransKeyEnum.SCHEDULE,
    path: `${caregiverRoute.base}${caregiverRoute.schedule}`,
    component: SchedulePageCaregiver,
  },
  {
    name: MenuNameTransKeyEnum.CRM,
    path: `${caregiverRoute.base}${caregiverRoute.crm}`,
    component: CrmPageCaregiver,
  },
  {
    name: MenuNameTransKeyEnum.TIME_CLOCK,
    path: `${caregiverRoute.base}${caregiverRoute.timeClock}`,
    component: TimeClockPageCaregiver,
  },
  {
    name: MenuNameTransKeyEnum.TASK,
    path: `${caregiverRoute.base}${caregiverRoute.task}`,
    component: TaskPageCaregiver,
  },
  {
    name: MenuNameTransKeyEnum.HR,
    path: `${caregiverRoute.base}${caregiverRoute.hr}`,
    component: HrPageCaregiver,
  },
  {
    path: `${caregiverRoute.base}${caregiverRoute.crmClient}/${caregiverRoute.clientId}`,
    component: CrmClientDetailPage,
  },
  {
    path: `${caregiverRoute.base}${caregiverRoute.crm}${caregiverRoute.crmProspect}/${caregiverRoute.clientId}`,
    component: CrmProspectDetailPage,
  },
  {
    path: `${caregiverRoute.base}${caregiverRoute.crm}${caregiverRoute.crmReferralSource}/${caregiverRoute.clientId}`,
    component: CrmReferralSourceDetailPage,
  },
  {
    path: `${caregiverRoute.base}${caregiverRoute.changePassword}`,
    component: ChangePasswordPageCaregiver,
  },
];
//#endregion Private Routes

//#region Absolute: Router && Path
export const authRouteAbsolute = {
  signIn: `${publicRoute.base}${publicRoute.auth}`,
  forgotPassword: `${publicRoute.base}${publicRoute.auth}${publicRoute.forgotPassword}`,
  verifyCode: `${publicRoute.base}${publicRoute.auth}${publicRoute.verifyCode}`,
  newPassword: `${publicRoute.base}${publicRoute.auth}${publicRoute.newPassword}`,
  changePasswordSuccess: `${publicRoute.base}${publicRoute.auth}${publicRoute.changePasswordSuccess}`,
  signUp: `${publicRoute.base}${publicRoute.auth}${publicRoute.signUp}`,
};

export const superAdminRouteAbsolute = {
  home: `${superAdminRoute.base}`,
  caregiver: `${superAdminRoute.base}${superAdminRoute.caregiver}`,
  requestApplicant: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.requestApplicant}`,
  careAlertsShowAll: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.careAlertsShowAll}`,
  caregiverShowAll: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverShowAll}`,
  caregiverReferralShowAll: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverReferralShowAll}`,
  crm: `${superAdminRoute.base}${superAdminRoute.crm}`,
  crmClient: `${superAdminRoute.base}${superAdminRoute.crmClient}`,
  crmProspect: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmProspect}`,
  addProspect: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmProspect}${superAdminRoute.addProspect}`,
  editProspect: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmProspect}${superAdminRoute.editProspect}`,
  crmReferralSource: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmReferralSource}`,
  messages: `${superAdminRoute.base}${superAdminRoute.messages}`,
  weeklyClients: `${superAdminRoute.base}${superAdminRoute.schedule}${superAdminRoute.weeklyClients}`,
  weeklyCaregivers: `${superAdminRoute.base}${superAdminRoute.schedule}${superAdminRoute.weeklyCaregivers}`,
  changePassword: `${superAdminRoute.base}${superAdminRoute.changePassword}`,
  ringCentral: `${superAdminRoute.base}${superAdminRoute.ringCentral}`,
  settings: `${superAdminRoute.base}${superAdminRoute.settings}`,
  task: `${superAdminRoute.base}${superAdminRoute.task}`,
  addCaregiver: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverShowAll}${superAdminRoute.addCaregiver}`,
  editCaregiver: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverShowAll}${superAdminRoute.editCaregiver}`,
  addCaregiverReferral: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverReferralShowAll}${superAdminRoute.addCaregiverReferral}`,
  editCaregiverReferral: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverReferralShowAll}${superAdminRoute.editCaregiverReferral}`,
  addClient: `${superAdminRoute.base}${superAdminRoute.crmClient}${superAdminRoute.addClient}`,
  editClient: `${superAdminRoute.base}${superAdminRoute.crmClient}${superAdminRoute.editClient}`,
  reporting: `${superAdminRoute.base}${superAdminRoute.reporting}`,
  reportingCaregiver: `${superAdminRoute.base}${superAdminRoute.reporting}${superAdminRoute.reportingCaregiver}`,
  reportClient: `${superAdminRoute.base}${superAdminRoute.reporting}${superAdminRoute.reportingClient}`,
  timeClockEmployee: `${superAdminRoute.base}${superAdminRoute.timeClock}${superAdminRoute.timeClockEmployee}`,
  timeClockCaregiver: `${superAdminRoute.base}${superAdminRoute.timeClock}${superAdminRoute.timeClockCaregiver}`,
  locationDetail: `${superAdminRoute.base}${superAdminRoute.locationDetail}`,
  createEmployees: `${superAdminRoute.base}${superAdminRoute.employees}${superAdminRoute.addEmployees}`,
  employeeDetail: `${superAdminRoute.base}${superAdminRoute.employees}${superAdminRoute.employeeDetail}`,
  addReferralSource: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmReferralSource}${superAdminRoute.addReferralSource}`,
  editReferralSource: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmReferralSource}${superAdminRoute.editReferralSource}`,
  addInitialCall: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmInitialCall}${superAdminRoute.createInitialCall}`,
  createContact: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmContact}${superAdminRoute.createContacts}`,
  editContact: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmContact}${superAdminRoute.editContacts}`,
  crmContact: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmContact}`,
  crmInitialCall: `${superAdminRoute.base}${superAdminRoute.crm}${superAdminRoute.crmInitialCall}`,
  createRequestApplicant: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.requestApplicant}${superAdminRoute.createRequestApplicant}`,
  editRequestApplicant: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.requestApplicant}${superAdminRoute.editRequestApplicant}`,
  caregiverReferralDetail: `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverReferralShowAll}`,
  invoiceDetail: `${superAdminRoute.base}${superAdminRoute.accounting}${superAdminRoute.invoice}`,
  hrEmployeeDetail: `${superAdminRoute.base}${superAdminRoute.hr}${superAdminRoute.hrEmployee}`,
  hrCaregiverDetail: `${superAdminRoute.base}${superAdminRoute.hr}${superAdminRoute.hrCaregiver}`,
};

export const adminRouteAbsolute = {
  home: `${adminRoute.base}`,
  caregiver: `${adminRoute.base}${adminRoute.caregiver}`,
  requestApplicant: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}`,
  careAlertsShowAll: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.careAlertsShowAll}`,
  caregiverShowAll: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}`,
  caregiverReferralShowAll: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}`,
  crm: `${adminRoute.base}${adminRoute.crm}`,
  crmClient: `${adminRoute.base}${adminRoute.crmClient}`,
  crmProspect: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmProspect}`,
  addProspect: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmProspect}${adminRoute.addProspect}`,
  editProspect: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmProspect}${adminRoute.editProspect}`,
  crmReferralSource: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmReferralSource}`,
  messages: `${adminRoute.base}${adminRoute.messages}`,
  weeklyClients: `${adminRoute.base}${adminRoute.schedule}${adminRoute.weeklyClients}`,
  weeklyCaregivers: `${adminRoute.base}${adminRoute.schedule}${adminRoute.weeklyCaregivers}`,
  changePassword: `${adminRoute.base}${adminRoute.changePassword}`,
  ringCentral: `${adminRoute.base}${adminRoute.ringCentral}`,
  settings: `${adminRoute.base}${adminRoute.settings}`,
  task: `${adminRoute.base}${adminRoute.task}`,
  addCaregiver: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}${adminRoute.addCaregiver}`,
  editCaregiver: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}${adminRoute.editCaregiver}`,
  addCaregiverReferral: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}${adminRoute.addCaregiverReferral}`,
  editCaregiverReferral: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}${adminRoute.editCaregiverReferral}`,
  addClient: `${adminRoute.base}${adminRoute.crmClient}${adminRoute.addClient}`,
  editClient: `${adminRoute.base}${adminRoute.crmClient}${adminRoute.editClient}`,
  reporting: `${adminRoute.base}${adminRoute.reporting}`,
  reportingCaregiver: `${adminRoute.base}${adminRoute.reporting}${adminRoute.reportingCaregiver}`,
  reportClient: `${adminRoute.base}${adminRoute.reporting}${adminRoute.reportingClient}`,
  timeClockEmployee: `${adminRoute.base}${adminRoute.timeClock}${adminRoute.timeClockEmployee}`,
  timeClockCaregiver: `${adminRoute.base}${adminRoute.timeClock}${adminRoute.timeClockCaregiver}`,
  locationDetail: `${adminRoute.base}${adminRoute.locationDetail}`,
  createEmployees: `${adminRoute.base}${adminRoute.employees}${adminRoute.addEmployees}`,
  employeeDetail: `${adminRoute.base}${adminRoute.employees}${adminRoute.employeeDetail}`,
  openShiftsCalendar: `${adminRoute.base}${adminRoute.schedule}${adminRoute.openShiftsCalendar}`,
  addReferralSource: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmReferralSource}${adminRoute.addReferralSource}`,
  editReferralSource: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmReferralSource}${adminRoute.editReferralSource}`,
  addInitialCall: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmInitialCall}${adminRoute.createInitialCall}`,
  createContact: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmContact}${adminRoute.createContacts}`,
  editContact: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmContact}${adminRoute.editContacts}`,
  crmContact: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmContact}`,
  crmInitialCall: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmInitialCall}`,
  createRequestApplicant: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}${adminRoute.createRequestApplicant}`,
  editRequestApplicant: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}${adminRoute.editRequestApplicant}`,
  caregiverReferralDetail: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}`,
  invoiceDetail: `${adminRoute.base}${adminRoute.accounting}${adminRoute.invoice}`,
  hrEmployeeDetail: `${adminRoute.base}${adminRoute.hr}${adminRoute.hrEmployee}`,
  hrCaregiverDetail: `${adminRoute.base}${adminRoute.hr}${adminRoute.hrCaregiver}`,
};

export const staffRouteAbsolute = {
  home: `${staffRoute.base}`,
  caregiver: `${staffRoute.base}${staffRoute.caregiver}`,
  requestApplicant: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}`,
  careAlertsShowAll: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.careAlertsShowAll}`,
  caregiverShowAll: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}`,
  caregiverReferralShowAll: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}`,
  messages: `${staffRoute.base}${staffRoute.messages}`,
  weeklyClients: `${staffRoute.base}${staffRoute.schedule}${staffRoute.weeklyClients}`,
  weeklyCaregivers: `${staffRoute.base}${staffRoute.schedule}${staffRoute.weeklyCaregivers}`,
  crm: `${staffRoute.base}${staffRoute.crm}`,
  crmClient: `${staffRoute.base}${staffRoute.crmClient}`,
  crmProspect: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmProspect}`,
  crmReferralSource: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmReferralSource}`,
  changePassword: `${staffRoute.base}${staffRoute.changePassword}`,
  task: `${staffRoute.base}${staffRoute.task}`,
  addCaregiver: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}${staffRoute.addCaregiver}`,
  editCaregiver: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}${staffRoute.editCaregiver}`,
  addCaregiverReferral: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}${staffRoute.addCaregiverReferral}`,
  editCaregiverReferral: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}${staffRoute.editCaregiverReferral}`,
  reporting: `${staffRoute.base}${staffRoute.reporting}`,
  reportingCaregiver: `${staffRoute.base}${staffRoute.reporting}${staffRoute.reportingCaregiver}`,
  reportClient: `${staffRoute.base}${staffRoute.reporting}${staffRoute.reportingClient}`,
  createRequestApplicant: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}${staffRoute.createRequestApplicant}`,
  editRequestApplicant: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}${staffRoute.editRequestApplicant}`,
  caregiverReferralDetail: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}`,
  timeClockEmployee: `${staffRoute.base}${staffRoute.timeClock}${staffRoute.timeClockEmployee}`,
  timeClockCaregiver: `${staffRoute.base}${staffRoute.timeClock}${staffRoute.timeClockCaregiver}`,
  hrCaregiverDetail: `${staffRoute.base}${staffRoute.hr}`,
  createEmployees: `${staffRoute.base}${staffRoute.employees}${staffRoute.addEmployees}`,
  employeeDetail: `${staffRoute.base}${staffRoute.employees}${staffRoute.employeeDetail}`,
  addInitialCall: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmInitialCall}${staffRoute.createInitialCall}`,
  crmInitialCall: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmInitialCall}`,
  addProspect: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmProspect}${staffRoute.addProspect}`,
  editProspect: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmProspect}${staffRoute.editProspect}`,
  addReferralSource: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmReferralSource}${staffRoute.addReferralSource}`,
  editReferralSource: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmReferralSource}${staffRoute.editReferralSource}`,
  crmContact: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmContact}`,
  createContact: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmContact}${staffRoute.createContacts}`,
  editContact: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmContact}${staffRoute.editContacts}`,
  locationDetail: `${staffRoute.base}${staffRoute.locationDetail}`,
  settings: `${staffRoute.base}${staffRoute.settings}`,
  addClient: `${staffRoute.base}${staffRoute.crmClient}${staffRoute.addClient}`,
  editClient: `${staffRoute.base}${staffRoute.crmClient}${staffRoute.editClient}`,
  invoiceDetail: `${staffRoute.base}${staffRoute.accounting}${staffRoute.invoice}`,
};

export const caregiverRouteAbsolute = {
  home: `${caregiverRoute.base}`,
  crm: `${caregiverRoute.base}${caregiverRoute.crm}`,
  crmClient: `${caregiverRoute.base}${caregiverRoute.crmClient}`,
  crmProspect: `${caregiverRoute.base}${caregiverRoute.crm}${caregiverRoute.crmProspect}`,
  crmReferralSource: `${caregiverRoute.base}${caregiverRoute.crm}${caregiverRoute.crmReferralSource}`,
  changePassword: `${caregiverRoute.base}${caregiverRoute.changePassword}`,
  task: `${caregiverRoute.base}${caregiverRoute.task}`,
};

export const publicRouteAbsolute = {};
//#endregion Absolute: Router && Path
