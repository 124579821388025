// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
import { EmployeeDetailTemplate } from '~/pages';
// Others
// Styles, images, icons
import styles from './EmployeeDetail.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const EmployeeDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook

  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='employeeDetailStaffPage' className={cx('container')}>
      <EmployeeDetailTemplate />
    </div>
  );
};

export default EmployeeDetail;
