// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { TFunction } from 'i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseInput,
  Modal,
  Loading,
  BaseGoogleMap,
  ModalUnderDevelopment,
  SelectTime,
  BaseSelect,
  PlacesAutocomplete,
  BaseDatePicker,
} from '~/components';
// Others
import { ButtonTypeEnum, PageTimeClockEnum, StatusEnum, StatusTimeClockTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { useAppDispatch } from '~/redux/hooks';
import {
  IChangeStatusTimeClockBody,
  IDataEditTimeClock,
  IDetailTimeClock,
  IFormEditTimeClock,
} from '~/utils/interface/timeClock';
import { IAddress, IBaseOption, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { ISelectTask } from '~/utils/interface/task';
import { convertTime, getUserName } from '~/utils/helper';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { googleMapApiKey } from '~/utils/constants/env';
import { getSelectTask } from '~/thunks/task/taskThunk';
import { changeStatusTimeClock, getDetailTimeClock, updateTimeClock } from '~/thunks/timeClock/timeClockThunk';
import { timeClockActions } from '~/thunks/timeClock/timeClockSlice';
import { formatDateTimeZone } from './helper';

// Styles, images, icons
import styles from './FormEditTimeClockModal.module.scss';

type Props = {
  timeClockId: string;
  isOpen: boolean;
  pageTimeClock?: PageTimeClockEnum;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    taskId: yup.string().optional(),
    startDate: yup.string().optional(),
    endDate: yup
      .string()
      .optional()
      .test('is-endDate-valid', t('modal_update_time_clock_end_date_invalid'), function (value) {
        const { startDate } = this.parent;

        return !value || !startDate || new Date(value) >= new Date(startDate);
      }),
    checkIn: yup.string().optional(),
    checkOut: yup
      .string()
      .optional()
      .test('is-time-valid', t('modal_update_time_clock_end_time_invalid'), function (checkOut) {
        const { startDate, endDate, checkIn } = this.parent;

        if (!startDate || !endDate || !checkIn || !checkOut) return true;

        if (startDate === endDate) {
          const parsedCheckIn = moment(checkIn, 'HH:mm');
          const parsedCheckOut = moment(checkOut, 'HH:mm');

          return parsedCheckOut.isAfter(parsedCheckIn, 'minute');
        }

        return true;
      }),
  });
};

const defaultForm: IFormEditTimeClock = {
  startDate: EMPTY_STRING,
  endDate: EMPTY_STRING,
  checkIn: EMPTY_STRING,
  checkOut: EMPTY_STRING,
  address: EMPTY_STRING,
  city: EMPTY_STRING,
  country: EMPTY_STRING,
  postalCode: EMPTY_STRING,
  state: EMPTY_STRING,
  taskId: EMPTY_STRING,
  totalBreak: DEFAULT_NUMBER_ZERO,
  clientName: EMPTY_STRING,
  userName: EMPTY_STRING,
  locationClientId: EMPTY_STRING,
  endTime: EMPTY_STRING,
  startTime: EMPTY_STRING,
};

const FormEditTimeClockModal = (props: Props) => {
  //#region Destructuring Props
  const { timeClockId, isOpen, pageTimeClock, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IFormEditTimeClock>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultForm,
  });

  const lng = useWatch({
    control,
    name: 'lng',
  });
  const lat = useWatch({
    control,
    name: 'lat',
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [optionsTask, setOptionsTask] = useState<IBaseOption[]>([]);
  const [status, setStatus] = useState<string>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUnderDevelopment, setIsUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!timeClockId) return;
    handleGetListTask();
    handleGetDetailTimeClock(timeClockId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeClockId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailTimeClock = (timeClockId: string) => {
    if (!timeClockId) return;

    setIsLoading(true);
    dispatch(getDetailTimeClock(timeClockId))
      .unwrap()
      .then((resp) => {
        const data: IDetailTimeClock = resp?.data;

        if (!data) return;

        const { id, task, ...remainingObject } = data;

        setStatus(data.status);

        const updatedFormData: IFormEditTimeClock = {
          lat: pageTimeClock === PageTimeClockEnum.CAREGIVER ? Number(data?.locationClient?.lat) : Number(data?.lat),
          lng: pageTimeClock === PageTimeClockEnum.CAREGIVER ? Number(data?.locationClient?.lng) : Number(data?.lng),
          taskId: data?.task?.id || EMPTY_STRING,
          startDate: data.startTime ? convertTime(data.startTime, TimeFormatEnum.YYYY_MM_DD) : EMPTY_STRING,
          endDate: data.endTime ? convertTime(data.endTime, TimeFormatEnum.YYYY_MM_DD) : EMPTY_STRING,
          checkIn: convertTime(data.startTime, TimeFormatEnum.HH_MM) || EMPTY_STRING,
          checkOut: data.endTime ? convertTime(data.endTime, TimeFormatEnum.HH_MM) : EMPTY_STRING,
          totalBreak: data.totalBreak ? Number(data.totalBreak) : DEFAULT_NUMBER_ZERO,
          address: pageTimeClock === PageTimeClockEnum.CAREGIVER ? data?.locationClient?.address : data?.address,
          city: pageTimeClock === PageTimeClockEnum.CAREGIVER ? data?.locationClient?.city : data?.city,
          state: pageTimeClock === PageTimeClockEnum.CAREGIVER ? data?.locationClient?.state : data?.state,
          postalCode: pageTimeClock === PageTimeClockEnum.CAREGIVER ? data?.locationClient?.zipCode : data?.postalCode,
          country: pageTimeClock === PageTimeClockEnum.CAREGIVER ? data?.locationClient?.country : data?.country,
          ...(data.client
            ? { clientName: getUserName(data.client?.firstName, data.client?.middleName, data.client?.lastName) }
            : {}),
          startTime: data.startTime || EMPTY_STRING,
          endTime: data.endTime || EMPTY_STRING,
          userName: getUserName(data.account.firstName, data.account.middleName, data.account.lastName),
        };

        reset(updatedFormData);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleGetListTask = () => {
    const paramsObject: IListQueryParams = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getSelectTask(paramsObject))
      .unwrap()
      .then((response) => {
        const { responses }: IListDataResponse<ISelectTask[]> = response?.data;
        if (!responses) return;

        setOptionsTask(convertListTaskOption(responses));
      })
      .catch((error) => {});
  };

  const handleOnSubmit = (data: IFormEditTimeClock) => {
    setIsLoading(true);
    const { userName, clientName, startDate, startTime, endDate, endTime, checkIn, checkOut, ...restData } = data;

    if (pageTimeClock === PageTimeClockEnum.CAREGIVER) {
      delete restData.taskId;
      delete restData.totalBreak;
    }

    const body: IDataEditTimeClock = {
      timeClockId: timeClockId,
      body: {
        startTime: formatDateTimeZone(startDate, checkIn),
        ...(endDate && checkOut ? { endTime: formatDateTimeZone(endDate, checkOut) } : {}),
        ...restData,
      },
    };
    dispatch(updateTimeClock(body))
      .unwrap()
      .then((res) => {
        onClose();
        dispatch(timeClockActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const convertListTaskOption = (listTaskResp: ISelectTask[]): IBaseOption[] => {
    if (!Array.isArray(listTaskResp)) {
      return [];
    }

    return listTaskResp.map((task) => ({
      label: task.description || EMPTY_STRING,
      value: task.id,
    }));
  };

  const handleEndBreak = () => {
    handleChangeStatusTimeClock(StatusTimeClockTypeEnum.RESUME);
  };

  const handleCheckOutModal = () => {
    handleChangeStatusTimeClock(StatusTimeClockTypeEnum.CHECK_OUT);
  };

  const handleChangeStatusTimeClock = (status: StatusTimeClockTypeEnum) => {
    const data: IChangeStatusTimeClockBody = {
      timeClockId: timeClockId,
      body: {
        type: status,
      },
    };
    setIsLoading(true);

    dispatch(changeStatusTimeClock(data))
      .unwrap()
      .then((res) => {
        setStatus(res.data);
        onClose();
        dispatch(timeClockActions.setRefreshList(true));
      })
      .catch((_err) => {})
      .finally(() => setIsLoading(false));
  };

  const handleChangeAddress = (address: IAddress) => {
    setValue('address', address?.address ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.city && setValue('city', address?.city ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.state && setValue('state', address?.state ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.zipCode &&
      setValue('postalCode', address?.zipCode ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.country &&
      setValue('country', address?.country ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });

    setValue('lat', address.lat);
    setValue('lng', address.lng);
  };

  const handleCloseUnderDevelopment = () => {
    setIsUnderDevelopment(false);
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('modal_update_time_clock_title')}>
      <form id='editTimeClockComponent' className={cx('addTimeClockComponent')} onSubmit={handleSubmit(handleOnSubmit)}>
        <div className={cx('contentModal')}>
          <div className={cx('bodyModal')}>
            <div className={cx('twoCol')}>
              <Controller
                name={'startDate'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseDatePicker
                    name='startDate'
                    label={t('modal_update_time_clock_start_date_label')}
                    onDateSelected={onChange}
                    value={value || EMPTY_STRING}
                    placeholderText={t('common_placeholder_select')}
                    errorMessage={errors.startDate?.message}
                  />
                )}
              />

              <Controller
                name={'checkIn'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <SelectTime
                    name={'startTime'}
                    label={t('modal_update_time_clock_check_in_label')}
                    placeholder={t('common_placeholder_select')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    errorMessage={errors.checkIn?.message}
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name={'endDate'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseDatePicker
                    label={t('modal_update_time_clock_end_date_label')}
                    onDateSelected={onChange}
                    name='endDate'
                    value={value || EMPTY_STRING}
                    placeholderText={t('common_placeholder_select')}
                    disable={status !== StatusTimeClockTypeEnum.SUBMITTED}
                    errorMessage={errors.endDate?.message}
                  />
                )}
              />

              <Controller
                name={'checkOut'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <SelectTime
                    name={'endTime'}
                    label={t('modal_update_time_clock_check_out_label')}
                    placeholder={t('common_placeholder_select')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    disabled={status !== StatusTimeClockTypeEnum.SUBMITTED}
                    errorMessage={errors.checkOut?.message}
                  />
                )}
              />
            </div>

            {pageTimeClock === PageTimeClockEnum.EMPLOYEE && (
              <Controller
                name={'totalBreak'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseInput
                    id='totalBreak'
                    name={name}
                    label={t('modal_update_time_clock_total_break_label')}
                    value={value}
                    onChange={(event) => {
                      let { value } = event.target;

                      if (!isNaN(Number(value))) {
                        onChange(Number(value));
                      }
                    }}
                    disabled={status === StatusEnum.APPROVED}
                  />
                )}
              />
            )}

            <div className={cx('twoCol')}>
              <Controller
                name={'userName'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseInput
                    id={name}
                    label={t('modal_update_time_clock_user_name_label')}
                    onChange={onChange}
                    value={value}
                    disabled
                    messageError={errors.userName?.message}
                  />
                )}
              />

              {pageTimeClock === PageTimeClockEnum.CAREGIVER ? (
                <Controller
                  name={'clientName'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <BaseInput
                      id={name}
                      label={t('modal_update_time_clock_client_name_label')}
                      onChange={onChange}
                      value={value}
                      messageError={errors.clientName?.message}
                      disabled
                    />
                  )}
                />
              ) : (
                <Controller
                  name={'taskId'}
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <BaseSelect
                      name={name}
                      options={optionsTask}
                      label={t('modal_update_time_clock_task_name_label')}
                      placeholder={t('common_select_placeholder')}
                      onChange={(item) => onChange(item.value)}
                      value={value}
                      disabled={status === StatusEnum.APPROVED}
                      errorMessage={errors.taskId?.message}
                    />
                  )}
                />
              )}
            </div>

            <Controller
              name={'address'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <PlacesAutocomplete
                  id='address'
                  value={value || EMPTY_STRING}
                  label={t('modal_update_time_clock_address_label')}
                  onChange={(address) => handleChangeAddress(address)}
                  messageError={errors.address?.message}
                  disabled
                />
              )}
            />

            <div className={cx('mapViewContainer')}>
              <span className={cx('textLabel')}>{t('modal_add_time_clock_map_view_label')}</span>
              <div className={cx('mapView')}>
                {isLoaded && (
                  <BaseGoogleMap
                    position={
                      lat && lng
                        ? {
                            lat: lat,
                            lng: lng,
                          }
                        : undefined
                    }
                  />
                )}
              </div>
            </div>
          </div>

          <div className={cx('footerModal')}>
            {status && status === StatusTimeClockTypeEnum.BREAK && (
              <BaseButton
                text={t('modal_add_time_clock_end_break_btn')}
                onClick={handleEndBreak}
                type='button'
                typeStyle={ButtonTypeEnum.PRIMARY}
              />
            )}

            {status && status !== StatusTimeClockTypeEnum.SUBMITTED && status !== StatusTimeClockTypeEnum.APPROVED && (
              <BaseButton
                text={t('form_shift_schedule_modal_check_out_label')}
                onClick={handleCheckOutModal}
                type='button'
                typeStyle={ButtonTypeEnum.PRIMARY}
              />
            )}

            <BaseButton
              text={t('common_update_label')}
              typeStyle={ButtonTypeEnum.PRIMARY}
              type='submit'
              width={80}
              disabled={!isDirty}
            />
          </div>
        </div>
      </form>

      {isLoading && <Loading />}

      {isUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseUnderDevelopment} />}
    </Modal>
  );
};

export default FormEditTimeClockModal;
