// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '~/redux/hooks';
// Components, Layouts, Pages
import {
  BaseTable,
  BasePagination,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  InputSearch,
  ToolBar,
  BaseButton,
  ModalChangeStatus,
  BaseFilter,
  BaseSelect,
  SendEmailMultipleModal,
} from '~/components';
// Others
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  CLientStatusEnum,
  CRMEnum,
  ReferralRoleFilterReferralSourceEnum,
  StatusFilterEnum,
  StorageEnum,
  TranslationEnum,
} from '~/utils/enum';
import { ColumnTableType, IFormSendMail, IListDataResponse, IPaginationResponse } from '~/utils/interface/common';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  OPTIONS_REFERRAL_STATUS,
  OPTIONS_Role_REFERRAL_SOURCE,
  optionsStatusReferralSource,
} from '~/utils/constants/common';
import { formatPhoneNumber, getUserName, removeEmptyObjects } from '~/utils/helper';
import useDebounce from '~/utils/customHook';
import { LoadingData, RingCentralContext } from '~/context';
import {
  adminRouteAbsolute,
  caregiverRouteAbsolute,
  staffRouteAbsolute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';
import { changeStatusClient, getListClients } from '~/thunks/crm/clients/clientsThunk';
import {
  IFilterClient,
  IListClient,
  IListQueryParamsClients,
  IPayloadChangeStatusClient,
} from '~/utils/interface/crm/clients';
import { IFilterReferralSource } from '~/utils/interface/crm/referralSource';
import { clientActions, selectIsRefreshClientList } from '~/thunks/crm/clients/clientsSlice';
import { sendEmailCaregivers } from '~/thunks/caregiver/caregiverThunk';
// Styles, images, icons
import styles from './CRMReferralSource.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleCall: (record: IListClient) => void,
  handleSendEmail: (record: IListClient) => void,
  handleViewDetails: (id: string) => void,
  handleSendMessage: (record: IListClient) => void,
  handleShowChangeStatus: (record: IListClient) => void,
  handleEdit: (id: string) => void
): ColumnTableType<IListClient>[] => {
  return [
    {
      key: 'organization',
      title: t('crm_referral_source_tab_organization_label'),
      dataIndex: 'organizationName',
      render: (_, record) => {
        return <>{record?.organizationName || EMPTY_STRING}</>;
      },
    },
    {
      key: 'name',
      title: t('crm_referral_source_tab_name_label'),
      // dataIndex: 'firstName',
      render: (_, record) => {
        return <>{getUserName(record?.firstName, record?.middleName, record?.lastName)}</>;
      },
    },
    {
      key: 'owner',
      title: t('crm_referral_source_tab_owner_label'),
      dataIndex: 'owner',
      render: (_, record) => {
        return <>{record.owner || EMPTY_STRING}</>;
      },
    },
    {
      key: 'workPhone',
      title: t('crm_referral_source_tab_workPhone_label'),
      dataIndex: 'workPhone',
      render: (_, record) => {
        return <>{record?.workPhone ? formatPhoneNumber(record?.workPhone) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'prospects',
      title: t('crm_referral_source_tab_prospects_label'),
      dataIndex: 'prospects',
      render: (_, record) => {
        return <>{record.prospects ?? EMPTY_STRING}</>;
      },
    },
    {
      key: 'client',
      title: t('crm_referral_source_tab_client_label'),
      dataIndex: 'clients',
      render: (_, record) => {
        return <>{record.clients ?? DEFAULT_NUMBER_ZERO}</>;
      },
    },
    {
      key: 'action',
      title: t('crm_referral_source_tab_action_label'),
      render(_, record) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isCall: true,
              isSendMessage: true,
              isSendEmail: true,
              isEdit: true,
              isDelete: false,
              isDeactivate: false,
              isChangeStatus: true,
            }}
            onView={() => handleViewDetails(record.id)}
            onCall={() => handleCall(record)}
            onSendEmail={() => handleSendEmail(record)}
            onSendMessage={() => handleSendMessage(record)}
            onChangeStatus={() => handleShowChangeStatus(record)}
            onEdit={() => handleEdit(record.id)}
          />
        );
      },
      width: 50,
    },
  ];
};

const CRMReferralSource = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const role = localStorage.getItem(StorageEnum.ROLE);
  // const { ringCentralStatus, executeCall, sendSMS } = useContext(RingCentralContext);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshClientsList = useSelector(selectIsRefreshClientList);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const statusParams = useMemo<string>(() => String(params?.status || EMPTY_STRING), [params?.status]);
  const referralRoleParams = useMemo<string>(
    () => String(params?.referralRole || EMPTY_STRING),
    [params?.referralRole]
  );
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [referralsList, setReferralsList] = useState<IListClient[]>([]);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [referralSourceSelected, setReferralSourceSelected] = useState<IListClient | null>(null);
  const [isShowSendMail, setIsShowSendMail] = useState<boolean>(false);
  const [isShowChangeStatus, setIsShowChangeStatus] = useState<boolean>(false);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState<boolean>(false);
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState<boolean>(false);

  // const [openDisconnectConfirm, setOpenDisconnectConfirm] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
      type: [CRMEnum.REFERRAL],
    };

    handleGetListReferrals(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!isRefreshClientsList) return;

    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_LIMIT_PAGE,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
      type: [CRMEnum.REFERRAL],
    };

    handleGetListReferrals(newParams);
    dispatch(clientActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshClientsList]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_LIMIT_PAGE.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleEdit = (record?: IListClient) => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleCall = async (record: IListClient) => {
    // if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) {
    //   setOpenDisconnectConfirm(true);
    //   return;
    // }

    // if (record?.workPhone) {
    //   executeCall && executeCall(record?.workPhone);
    // }

    setIsShowModalUnderDevelopment(true);
  };

  const handleSendMessage = (record: IListClient) => {
    // if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) {
    //   setOpenDisconnectConfirm(true);
    //   return;
    // }

    // if (record?.workPhone) {
    //   sendSMS && sendSMS(record?.workPhone);
    // }

    setIsShowModalUnderDevelopment(true);
  };

  const handleShowSendEmail = (record: IListClient) => {
    if (!record) return;

    setReferralSourceSelected(record);
    setIsShowSendMail(true);
  };

  const handleCloseSendEmail = () => {
    setReferralSourceSelected(null);
    setIsShowSendMail(false);
  };

  const handleShowChangeStatus = (record: IListClient) => {
    if (!record) return;

    setReferralSourceSelected(record);
    setIsShowChangeStatus(true);
  };

  const handleViewDetails = (id: string) => {
    if (!id) return;

    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.crmReferralSource}/${id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.crmReferralSource}/${id}`);
        return;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.crmReferralSource}/${id}`);
        return;

      case AccountRoleCodesEnum.CAREGIVER:
        navigate(`${caregiverRouteAbsolute.crmReferralSource}/${id}`);
        return;

      default:
        return;
    }
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handleSortBy = () => {
    // TODO - HuyPahmGRF-ABD: Handle Sort By
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleGetListReferrals = (params: IListQueryParamsClients) => {
    loading?.show();

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IListClient[]> = res?.data;
        setReferralsList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleAddReferralSource = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.addReferralSource}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.addReferralSource}`);
        return;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.addReferralSource}`);
        return;
    }
  };

  // const handleCloseDisconnectConfirm = () => {
  //   setOpenDisconnectConfirm(false);
  // };

  // const handleNavigateToRingCentral = () => {
  //   const role = localStorage.getItem(StorageEnum.ROLE);

  //   switch (role) {
  //     case AccountRoleCodesEnum.ADMIN:
  //       navigate(adminRouteAbsolute.ringCentral);
  //       break;

  //     case AccountRoleCodesEnum.EMPLOYEE:
  //       break;

  //     case AccountRoleCodesEnum.CAREGIVER:
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const handleReferralSourceRowClick = (data: IListClient) => {
    if (!data || !data?.id) return;

    handleViewDetails(data?.id);
  };

  const handleCloseChangeStatus = () => {
    setReferralSourceSelected(null);
    setIsShowChangeStatus(false);
  };

  const handleSubmitFilter = (valueFilter: IFilterClient | undefined) => {
    if (!valueFilter) {
      return;
    }

    const { status, referralRole, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.status === StatusFilterEnum.ALL ? {} : { status: valueFilter?.status }),
      ...(valueFilter?.referralRole === ReferralRoleFilterReferralSourceEnum.ALL
        ? {}
        : { referralRole: valueFilter.referralRole }),
    });
  };

  const handleChangeStatus = (status: string) => {
    setIsLoadingChangeStatus(true);

    if (!referralSourceSelected) return;

    const payloadClientStatus: IPayloadChangeStatusClient = {
      clientId: referralSourceSelected.id,
      body: {
        status: status as CLientStatusEnum,
      },
    };

    dispatch(changeStatusClient(payloadClientStatus))
      .unwrap()
      .then(() => {
        dispatch(clientActions.setRefreshList(true));
        handleCloseChangeStatus();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingChangeStatus(false);
      });
  };

  const handleOpenEdit = (id: string) => {
    if (!id) return;

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.editReferralSource}/${id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.editReferralSource}/${id}`);
        return;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.editReferralSource}/${id}`);
        return;
    }
  };

  const handleSendEmail = async (dataEmail: IFormSendMail) => {
    if (!referralSourceSelected?.id) return;
    setIsLoadingSendEmail(true);

    const formData = new FormData();

    const formDataFields = {
      from: dataEmail?.from || EMPTY_STRING,
      to: JSON.stringify(dataEmail?.to || []),
      cc: JSON.stringify(dataEmail?.cc || []),
      bcc: JSON.stringify(dataEmail?.bcc || []),
      subject: dataEmail?.subject || EMPTY_STRING,
      content: dataEmail?.content || EMPTY_STRING,
    };

    const dataFormatted = removeEmptyObjects(formDataFields);

    Object.entries(dataFormatted).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    if (dataEmail?.files) {
      dataEmail?.files?.forEach((file) => {
        file && formData.append('files', file);
      });
    }

    dispatch(sendEmailCaregivers(formData))
      .unwrap()
      .then((res) => {
        handleCloseSendEmail();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingSendEmail(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='CRMReferralSourceTemplate' className={cx('container')}>
      <ToolBar
        title={
          <div className={cx('crmTitle')}>
            <span>{t('crm_referral_source_tab_title')}</span>

            {/* {ringCentralStatus !== ConnectionStatusEnum.CONNECTED && (
              <span className={cx('crmRingCentralStatus')} onClick={handleNavigateToRingCentral}>
                {t('ring_central_confirm_disconnect_title')}
              </span>
            )} */}
          </div>
        }
      >
        {/* <BaseButton
          iconLeft={icons.commonIconSort}
          text={t('common_text_sort')}
          width={84}
          height={36}
          onClick={handleSortBy}
        />
        <BaseButton
          iconLeft={icons.commonIconSort}
          text={t('common_text_filter')}
          width={67}
          height={36}
          onClick={handleFilter}
        /> */}
        <InputSearch
          height={36}
          placeholder={t('common_placeholder_search_by_name')}
          onChange={handleChangeSearch}
          value={searchKey || EMPTY_STRING}
        />

        <BaseFilter<IFilterReferralSource>
          defaultValue={{
            status: StatusFilterEnum.ALL,
            referralRole: ReferralRoleFilterReferralSourceEnum.ALL,
          }}
          onApply={handleSubmitFilter}
          valueFilter={{
            status: statusParams || StatusFilterEnum.ALL,
            referralRole: referralRoleParams || ReferralRoleFilterReferralSourceEnum.ALL,
          }}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('filterWrap')}>
                <div className={cx('contentFilter')}>
                  <span className={cx('statusLabel')}>{t('crm_filter_referral_source_status_title')}</span>
                  <BaseSelect
                    width={175}
                    value={valueFilter?.status}
                    options={optionsStatusReferralSource}
                    onChange={(option) => {
                      onChange({
                        name: 'status',
                        value: option?.value.toString(),
                      });
                    }}
                  />
                </div>

                <div className={cx('contentFilter')}>
                  <span className={cx('statusLabel')}>{t('crm_filter_referral_source_type_title')}</span>
                  <BaseSelect
                    width={175}
                    value={valueFilter?.referralRole}
                    options={OPTIONS_Role_REFERRAL_SOURCE}
                    onChange={(option) => {
                      onChange({
                        name: 'referralRole',
                        value: option?.value.toString(),
                      });
                    }}
                  />
                </div>
              </div>
            );
          }}
        </BaseFilter>
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('crm_add_referral_title')}
          height={36}
          onClick={handleAddReferralSource}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(
              t,
              handleCall,
              handleShowSendEmail,
              handleViewDetails,
              handleSendMessage,
              handleShowChangeStatus,
              handleOpenEdit
            )}
            dataSource={referralsList}
            onClickRow={handleReferralSourceRowClick}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowSendMail && (
        <SendEmailMultipleModal
          isOpen={isShowSendMail}
          onClose={handleCloseSendEmail}
          onSendEmail={handleSendEmail}
          isLoading={isLoadingSendEmail}
          defaultEmails={referralSourceSelected?.email ? [referralSourceSelected?.email] : []}
        />
      )}

      {/* {openDisconnectConfirm && (
        <RingCentralConfirmModal
          isOpen={openDisconnectConfirm}
          title={t('ring_central_confirm_disconnect_title')}
          description={t('ring_central_confirm_disconnect_description')}
          confirm={t('ring_central_confirm_disconnect_confirm')}
          onClose={handleCloseDisconnectConfirm}
          onConfirm={handleNavigateToRingCentral}
        />
      )} */}

      {isShowChangeStatus && (
        <ModalChangeStatus
          isOpen={isShowChangeStatus}
          dataDefault={referralSourceSelected?.status || EMPTY_STRING}
          dataOption={OPTIONS_REFERRAL_STATUS}
          onClose={handleCloseChangeStatus}
          onSubmit={handleChangeStatus}
          isLoading={isLoadingChangeStatus}
        />
      )}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default CRMReferralSource;
