// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useLoadScript } from '@react-google-maps/api';
// Components, Layouts, Pages
import { LabelValueField, BaseGoogleMap } from '~/components';
// Others
import { DEFAULT_GG_MAP_LOAD_SCRIPT_LIB, EMPTY_STRING } from '~/utils/constants/common';
import { formatAddress, formatPhoneNumber } from '~/utils/helper';
import { googleMapApiKey } from '~/utils/constants/env';
import { useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
// Styles, images, icons
import styles from './InfoEmployeeTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const InfoEmployeeTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  const { userDetail } = useAppSelector((root: RootState) => root.userState);
  //#endregion Selector

  //#region Declare State

  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='infoEmployeeTabComponent' className={cx('infoTabComponent')}>
      <div className={cx('body')}>
        <div className={cx('infoDetailsWrap')}>
          <LabelValueField
            label={t('employee_detail_item_phone_number_label')}
            value={userDetail?.phoneNumber ? formatPhoneNumber(userDetail?.phoneNumber) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('employee_detail_item_alternate_phone_number_label')}
            value={userDetail?.altPhoneNumber ? formatPhoneNumber(userDetail?.altPhoneNumber) : EMPTY_STRING}
          />

          <LabelValueField label={t('client_detail_info_email_label')} value={userDetail?.email || EMPTY_STRING} />

          <LabelValueField
            label={t('employee_detail_item_secondary_email_label')}
            value={userDetail?.secondaryEmail ? formatPhoneNumber(userDetail?.secondaryEmail) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('employee_detail_item_employee_id_label')}
            value={userDetail?.id ? formatPhoneNumber(userDetail?.id) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('employee_detail_item_department_label')}
            value={userDetail?.department || EMPTY_STRING}
          />

          <LabelValueField
            label={t('prospect_tab_info_label_secondary_address')}
            value={userDetail?.addressLine2 || EMPTY_STRING}
          />

          <LabelValueField label={t('employee_detail_item_gender_label')} value={userDetail?.gender || EMPTY_STRING} />

          <LabelValueField
            label={t('employee_detail_item_nationality_label')}
            value={userDetail?.nationality || EMPTY_STRING}
          />

          <LabelValueField
            label={t('employee_detail_item_nri_resident_label')}
            value={userDetail?.nriResident || EMPTY_STRING}
          />
        </div>

        <div className={cx('mapSection')}>
          <div className={cx('viewAddress')}>
            <span className={cx('textLabel')}>{t('employee_detail_item_address_label')}</span>
            <span className={cx('valueContent')}>
              <span className={cx('valueContent')}>{formatAddress({ ...userDetail })}</span>
            </span>
          </div>

          <div className={cx('mapViewContainer')}>
            {isLoaded && (
              <BaseGoogleMap
                position={
                  userDetail?.lat && userDetail?.lng ? { lat: userDetail?.lat, lng: userDetail?.lng } : undefined
                }
                triggerLocate
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoEmployeeTab;
