export enum EnvironmentEnum {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAG = 'stag',
  PROD = 'prod',
}

export enum RolesEnum {
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
  CAREGIVER = 'CAREGIVER',
}

export enum StorageEnum {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  USER = 'user',
  STATUS_LOGIN = 'statusLogin',
  ROLE = 'role',
  USER_ID = 'userId',
  EMAIL = 'email',
  REMEMBER_ME = 'rememberMe',
  DEVICE_ID = 'deviceId',
  ACCOUNT_ID = 'accountId',
}

export enum LanguageEnum {
  EN = 'en',
}

export enum ButtonTypeEnum {
  PRIMARY = 'primary',
  ONLY_TEXT = 'onlyText',
  OUT_LINE = 'outline',
  WHITE = 'white',
  DELETE = 'delete',
}

export enum AccountRoleCodesEnum {
  ADMIN = '00001',
  EMPLOYEE = '00002',
  CAREGIVER = '00003',
  APPLICANT = '00004',
  MANAGER = '00005',
  SUPER_ADMIN = '00006',
}

export enum ImageCircleTypeEnum {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

// Router key translate
export enum MenuNameTransKeyEnum {
  HOME = 'menu_home',
  EMPLOYEES = 'menu_employees',
  HR = 'menu_hr',
  HR_CAREGIVER = 'menu_hr_caregiver',
  HR_EMPLOYEE = 'menu_hr_employee',
  CAREGIVER = 'menu_caregiver',
  CAREGIVERS = 'menu_caregivers',
  CAREGIVER_REFERRAL = 'menu_caregiver_referral',
  CAREGIVER_REQUEST_APPLICANT = 'menu_request_applicant',
  CRM = 'menu_crm',
  TIME_CLOCK = 'menu_time_clock',
  TIME_CLOCK_CAREGIVER = 'menu_time_clock_caregiver',
  TIME_CLOCK_EMPLOYEE = 'menu_time_clock_employee',
  REPORTING = 'menu_reporting',
  REPORTING_CAREGIVER = 'menu_reporting_caregiver',
  REPORTING_CLIENT = 'menu_reporting_client',
  REPORTING_INVOICE = 'menu_reporting_invoice',
  ACCOUNTING = 'menu_accounting',
  MESSAGE = 'menu_message',
  SCHEDULE = 'menu_schedule',
  TASK = 'menu_task',
  FORM = 'menu_form',
  DOCUMENT = 'menu_document',
  INVOICE = 'menu_invoice',
  LIVE_VIEW = 'menu_schedule_live',
  DAILY_CLIENTS = 'menu_schedule_live_daily_clients',
  DAILY_CAREGIVERS = 'menu_schedule_live_daily_caregivers',
  WEEKLY_CLIENTS = 'menu_schedule_live_weekly_clients',
  WEEKLY_CAREGIVERS = 'menu_schedule_live_weekly_caregivers',
  OFFICE_CALENDAR = 'menu_schedule_office_calendar',
  OPEN_SHIFT_CALENDAR = 'menu_schedule_open_shifts_calendar',
  CLIENT = 'menu_client',
  PROSPECT = 'menu_prospect',
  REFERRAL_SOURCE = 'menu_referral_source',
  LOCATION = 'menu_location',
  CONTACT = 'menu_contact',
  INITIAL_CALL = 'menu_initial_call',
  CAMPAIGN = 'menu_campaign',
}

// Tab key translate
export enum TabNameTransKeyEnum {
  DAILY_CARE = 'tab_daily_care',
  SCHEDULE = 'tab_schedule',
  CARE_PLAN = 'tab_care_plan',
  DOCUMENTS = 'tab_documents',
  SHIFT_SCHEDULE = 'tab_shift_schedule',
  CONTACT = 'tab_contact',
  ACTIVITIES = 'tab_activities',
  ACTIVITY = 'tab_activity',
  FILES = 'tab_files',
  SKILL = 'tab_skill',
  PAYROLL = 'tab_payroll',
  CHECK = 'tab_check',
  TRAINING = 'tab_training',
  COMMENTS = 'tab_comments',
  SUMMARY = 'tab_summary',
  NOTES = 'tab_notes',
  EDIT = 'tab_edit',
  COPY = 'tab_copy',
  DELETE = 'tab_delete',
  INTEGRATION = 'tab_integration',
  RATE = 'tab_rate',
  INFO = 'tab_info',
  CLIENTS_PROSPECTS_REFERRED = 'tab_clients_prospects_referred',
  CLIENTS_PROSPECTS = 'tab_clients_prospects',
  STAGE = 'tab_stage',
  EMAIL_TEMPLATE = 'email_template',
  CAREGIVER_APPLICANT_REFERRAL = 'tab_caregiver_applicant',
  BILLING_POLICY = 'tab_billing_policy',
  BILLING = 'tab_billing',
  HOME_SAFETY_ASSESSMENT = 'tab_home_safety_assessment',
}

export enum TabQueryValueEnum {
  DAILY_CARE = 'daily-care',
  SCHEDULE = 'schedule',
  CLIENT = 'client',
  PROSPECT = 'prospect',
  REFERRAL_SOURCE = 'referral_source',
  ACTIVITIES = 'activities',
  FILES = 'files',
  SKILL = 'skill',
  PAYROLL = 'payroll',
  CHECK = 'check',
  TRAINING = 'training',
  INFO = 'info',
  BILLING_POLICY = 'billing_policy',
}

export enum statusEnum {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  CONVERT = 'CONVERT',
  COMPLETE = 'COMPLETE',
  IN_COMPLETE = 'INCOMPLETE',
}

export enum statusLabelEnum {
  COMPLETE = 'common_complete_label',
  IN_COMPLETE = 'common_in_complete_label',
}

export enum InputTypeEnum {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export enum InputTypeStyleEnum {
  SEARCH_SERVICE = 'service',
  SELECT = 'select',
}

export enum TranslationEnum {
  TRANSLATION = 'translation',
}

export enum TimeFormatEnum {
  HOUR_MINUTE_AM_PM = 'h:mm A',
  HOUR_MINUTE_A = 'hh:mm A',
  HH_MM = 'HH:mm',
  YYYY_MM_DD_HH_MM = 'YYYY-MM-DD HH:mm',
  MM_DD_YYYY_HH_AM_PM = 'MM/DD/YYYY h:mm A',
  MMMM_D_YYYY = 'MMMM D, YYYY',
  MMMM_DD_YYYY = 'MMMM DD, YYYY',
  DD_MMM_YYYY = 'DD MMM YYYY',
  YYYY_MM_DD = 'YYYY-MM-DD',
  MMMM_D_YYYY_NORMAL = 'MMMM D YYYY',
  HOUR_MINUTE_AM_PM_NORMAL = 'h:mm a',
  D_MMM_YYYY = 'D MMM, YYYY',
  MM_DD_YY = 'MM/DD/YY',
  MMMM_D_HH_MM_A = 'MMMM D, hh:mm A',
  MM_DD_YYYY = 'MM/DD/YYYY',
  MMMM_DD = 'MMMM DD',
  DD_YYYY = 'DD, YYYY',
  DDDD_MMMM_DD_YYYY = 'dddd, MMMM DD, YYYY',
  MMMM_YYYY = 'MMMM, YYYY',
  HH_MM_SS = 'HH:mm:ss',
}

export enum DateFormatEnum {
  D_MMM_YYYY = 'D MMM, YYYY',
  YYYY_MM_DD = 'YYYY-MM-DD',
  DDD = 'ddd',
  DD_MM_YYYY = 'DD MM YYYY',
}

export enum StatusTypeEnum {
  ONLINE = 'common_status_online_label',
  OFFLINE = 'common_status_offline_label',
}

export enum ScheduleNavigateActionEnum {
  PREV = 'PREV',
  NEXT = 'NEXT',
  TODAY = 'TODAY',
  DATE = 'DATE',
}

export enum StatusEnum {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  CONVERT = 'CONVERT',
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  OUTSTANDING = 'OUTSTANDING',
  IN_COMPLETE = 'INCOMPLETE',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NEW_LEAD = 'New Lead',
  INITIAL_PHONE_CALL = 'Initial Phone Call',
  ASSESSMENT_SCHEDULED = 'ASSESSMENT_SCHEDULED',
  ASSESSMENT_PERFORMED = 'ASSESSMENT_PERFORMED',
  BREAK = 'BREAK',
  TODO = 'TODO',
  DONE = 'DONE',
  EXPIRED = 'EXPIRED',
  MISSED_CHECK_IN = 'MISSED_CHECK_IN',
  MISSED_CHECK_OUT = 'MISSED_CHECK_OUT',
  OPEN_SHIFT = 'OPEN_SHIFT',
  CANCELLED_BY_CLIENT = 'CANCELLED_BY_CLIENT',
  CANCELLED_BY_CAREGIVER = 'CANCELLED_BY_CAREGIVER',
  CANCELLED_BY_OFFICE = 'CANCELLED_BY_OFFICE',
  NOT_SCHEDULED = 'NOT_SCHEDULED',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  ATTENTION_REQUIRED = 'ATTENTION_REQUIRED',
  SHOW_ALL_TYPES = 'SHOW_ALL_TYPES',
  GENERAL = 'GENERAL',
  MEETING = 'MEETING',
  VACATION_TIME_OFF = 'VACATION_TIME_OFF',
  HOLIDAY = 'HOLIDAY',
  TASK = 'TASK',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUND = 'REFUND',
  SHOW_ALL = 'SHOW_ALL',
  OPEN = 'OPEN',
  SENT = 'SENT',
  DUE_DATE = 'DUE_DATE',
  SENT_INVOICE = 'SENT_INVOICE',
  SENT_PAYMENT = 'SENT_PAYMENT',
  PASSED = 'PASSED',
  NOT_PASSED = 'NOT_PASSED',
  UNAVAILABILITY = 'UNAVAILABILITY',
  CLOSED = 'CLOSED',
  SUSPENDED = 'SUSPENDED',
  DECEASED = 'DECEASED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  EXPORTED = 'EXPORTED',
  APPROVED = 'APPROVED',
}

export enum EventStatusEnum {
  SCHEDULED = 'SCHEDULED',
  NOT_SCHEDULED = 'NOT_SCHEDULED',
  COMPLETE = 'COMPLETE',
  NOT_COMPLETE = 'NOT_COMPLETE',
  CANCELLED_BY_CLIENT = 'CANCELLED_BY_CLIENT',
  CANCELLED_BY_CAREGIVER = 'CANCELLED_BY_CAREGIVER',
  CANCELLED_BY_OFFICE = 'CANCELLED_BY_OFFICE',
  TENTATIVE = 'TENTATIVE',
  ATTENTION_REQUIRED = 'ATTENTION_REQUIRED',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  CAREGIVER_NO_SHOW = 'CAREGIVER_NO_SHOW',
  GENERAL = 'GENERAL',
  MEETING = 'MEETING',
  VACATION_TIME_OFF = 'VACATION_TIME_OFF',
  HOLIDAY = 'HOLIDAY',
  TASK = 'TASK',
  UNAVAILABILITY = 'UNAVAILABILITY',
}

export enum SelectTypeEnum {
  PRIMARY = 'primary',
}

// Type
export enum ResponseStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  ERROR = 'error',
}

export enum HTMLEventEnum {
  KEYDOWN = 'keydown',
  ESCAPE = 'Escape',
  ENTER = 'Enter',
  RESIZE = 'resize',
  SCROLL = 'scroll',
  SPACE = ' ',
}

// Toast Message
export enum ToastTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export enum MethodRequestServerTypeEnum {
  GET = 'get',
}

export enum ToastPositionTypeEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

// Message
export enum MessageStatusEnum {
  DELIVERED = 'DELIVERED',
  SEEN = 'SEEN',
  SENT = 'SENT',
}

export enum MessageTypeEnum {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  PHOTO = 'PHOTO',
  AUDIO = 'AUDIO',
  FILE = 'FILE',
  EMOTION = 'EMOTION',
  LOCATION = 'LOCATION',
  IMAGE = 'IMAGE',
}

export enum SignInNameEnum {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export enum WebSocketEvent {
  LOGIN = 'LOGIN',
  OFFLINE = 'OFFLINE',
  CONNECT_SUCCESS = 'CONNECT_SUCCESS',
  TYPING = 'TYPING',
  STOP_TYPING = 'STOP_TYPING',
  JOIN_LIST_CONVERSATION = 'JOIN_LIST_CONVERSATION',
  LEAVE_LIST_CONVERSATION = 'LEAVE_LIST_CONVERSATION',
  JOIN_CONVERSATION = 'JOIN_CONVERSATION',
  LEAVE_CONVERSATION = 'LEAVE_CONVERSATION',
  SEND_MESSAGE = 'SEND_MESSAGE',
  SEEN_MESSAGE = 'SEEN_MESSAGE',
  GET_MESSAGE_UNSEEN = 'GET_MESSAGE_UNSEEN',
  NEW_NOTIFICATION = 'NEW_NOTIFICATION',
  DELIVERED_MESSAGE = 'DELIVERED_MESSAGE',
}

export enum WebSocketType {
  CHAT = 'CHAT',
  USE_APP = 'USE_APP',
}

export enum ForgotPasswordNameEnum {
  EMAIL = 'email',
}

export enum NewPasswordNameEnum {
  NEW_PASSWORD = 'newPassword',
  CONFIRM_NEW_PASSWORD = 'confirmNewPassword',
}

export enum CLientStatusEnum {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  ON_HOLD = 'ON_HOLD',
  ACTIVATE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
  DEACTIVATE = 'INACTIVE',
}

export enum TabQueryClientDetailEnum {
  INFO = 'info',
  DAILY_CARE = 'daily_care',
  CARE_PLAN = 'care-plan',
  SHIFT_SCHEDULE = 'shift_schedule',
  DOCUMENTS = 'documents',
  ACTIVITIES = 'activities',
  CONTACT = 'contact',
  PAYROLL_RATES = 'payroll_rates',
  BILLING_POLICY = 'billing_policy',
  BILLING = 'billing',
  HOME_SAFETY_ASSESSMENT = 'home_safety_assessment',
}

export enum TabQueryReferralSourceDetailEnum {
  CLIENTS_PROSPECTS_REFERRED = 'clients-prospects-referred',
  INFO = 'info',
}

export enum TabQueryCaregiverReferralDetailEnum {
  INFO = 'info',
  CAREGIVER_APPLICANT_REFERRED = 'caregiver_applicant_referred',
}

export enum TabQueryEmployeeDetailEnum {
  INFO = 'info',
}

export enum TabQueryEventEnum {
  SUMMARY = 'summary',
  NOTES = 'notes',
  EDIT = 'edit',
  COPY = 'copy',
  DELETE = 'delete',
}

export enum AddCRMItemEnum {
  CLIENT = '1',
  PROSPECT = '2',
  REFERRAL_SOURCE = '3',
}

export enum HRCarInsuranceEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum ButtonStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum TypeDocumentsEnum {
  PDF = 'PDF',
  FILE_FOLDER = 'FILE_FOLDER',
}

export enum CRMEnum {
  CLIENT = 'CLIENT',
  REFERRAL = 'REFERRAL',
  PROSPECT = 'PROSPECT',
  USER = 'USER',
}

export enum ReferrerEnum {
  None = 'None',
  Self = 'Self',
}

export enum ReferrerRoleEnum {
  ORGANIZATION = 'ORGANIZATION',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum InputCRMEnum {
  POSTAL_CODE = 'postalCode',
  HOME_PHONE = 'homePhone',
  MOBILE_PHONE = 'mobilePhone',
  WORK_PHONE = 'workPhone',
  FAX_NUMBER = 'faxNumber',
  POSTAL_CODE_INDIVIDUAL = 'postalCodeIndividual',
  HOME_PHONE_INDIVIDUAL = 'homePhoneIndividual',
  MOBILE_PHONE_INDIVIDUAL = 'mobilePhoneIndividual',
  WORK_PHONE_INDIVIDUAL = 'workPhoneIndividual',
  FAX_NUMBER_INDIVIDUAL = 'faxNumberIndividual',
  POSTAL_CODE_ORGANIZATION = 'postalCodeOrganization',
  HOME_PHONE_ORGANIZATION = 'homePhoneOrganization',
  MOBILE_PHONE_ORGANIZATION = 'mobilePhoneOrganization',
  WORK_PHONE_ORGANIZATION = 'workPhoneOrganization',
  FAX_NUMBER_ORGANIZATION = 'faxNumberOrganization',
}

export enum RadioCrmEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
  HOURLY = 'HOURLY',
  PER_VISIT = 'PER_VISIT',
  LIVE_IN = 'LIVE_IN',
  NOT_BILLABLE = 'NOT_BILLABLE',
  NOT_PAYABLE = 'NOT_PAYABLE',
}

export enum TabQueryTaskDetailEnum {
  COMMENTS = 'comments',
  ACTIVITY = 'activity',
}

export enum FormAddTaskEnum {
  ACCOUNT_ID = 'accountId',
  NAME = 'name',
  DESCRIPTION = 'description',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TAGS = 'tags',
  PRIORITY = 'priority',
  STATUS = 'status',
  ASSIGNEE = 'assignee',
}

export enum TagsEnum {
  DESIGN = 'Design',
  PRODUCT = 'Product',
  MARKETING = 'Marketing',
  DESIGN_SYSTEM = 'Design System',
}

export enum TYPE_FILE_SIZE {
  GB = 'GB',
  MB = 'MB',
  KB = 'KB',
  B = 'B',
}

export enum TYPE_FILE {
  PDF = 'PDF',
  XLSX = 'XLSX',
  XLS = 'XLS',
  XLSM = 'XLSM',
  XLTX = 'XLTX',
  XLTM = 'XLTM',
  XLAM = 'XLAM',
  XLSB = 'XLSB',
  DOCX = 'DOCX',
  DOC = 'DOC',
  DOTX = 'DOTX',
  DOCM = 'DOCM',
  DOTM = 'DOTM',
  PPTX = 'PPTX',
  PPT = 'PPT',
  POTM = 'POTM',
  PPTM = 'PPTM',
  POTX = 'POTX',
  PPSX = 'PPSX',
  PPSM = 'PPSMs',
  IMAGE = 'Image',
  FOLDER = 'File Folder',
  VIDEO = 'Video',
  AUDIO = 'Audio',
}

export enum StatusTimeClockTypeEnum {
  CHECK_IN = 'CHECK_IN',
  CHECK_OUT = 'CHECK_OUT',
  BREAK = 'BREAK',
  RESUME = 'RESUME',
  IN_PROGRESS = 'IN_PROGRESS',
  TODO = 'TODO',
  DONE = 'DONE',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
}

export enum EditTaskModalEnum {
  ACCOUNT_ID = 'AccountId',
  NAME = 'Name',
  DESCRIPTION = 'Description',
  START_DATE = 'Start Date',
  END_DATE = 'End Date',
  TAGS = 'Tags',
  PRIORITY = 'Priority',
  STATUS = 'Status',
  ASSIGNEE = 'Assignee',
}

export enum AddScheduleModalEnum {
  ACCOUNT_ID = 'accountId',
  NAME_TASK = 'nameTask',
  ASSIGNEES = 'assignees',
  ALL_DAY = 'isAllDay',
  TIME_ZONE = 'timeZone',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  RECURRENCE = 'recurrence',
  CAREGIVER = 'caregiver',
  CLIENT_NAME = 'clientName',
}

export enum AllDayAddScheduleEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum FormTimeClockKeyEnum {
  USER_NAME = 'userName',
  TASK_ID = 'taskId',
  TASK_NAME = 'taskName',
  PROJECT_NAME = 'projectName',
  LOCATION = 'location',
  CHECK_IN = 'checkIn',
  CHECK_OUT = 'checkOut',
  TOTAL_BREAK = 'totalBreak',
  DURATION = 'duration',
  BUSINESS_ID = 'businessId',
}

export enum FormEmployeeKeyEnum {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ROLE = 'role',
}

export enum TypeScheduleEnum {
  MY = 'MY',
  FULL = 'FULL',
}

export enum TaskIncludeInEnum {
  CALENDARS = 'CALENDARS',
  CAREGIVER_PORTAL = 'CAREGIVER_PORTAL',
  PRINTED_CALENDARS = 'PRINTED_CALENDARS',
  FAMILY_ROOM = 'FAMILY_ROOM',
}

export enum TypeFilesDocumentEnum {
  CLIENT_DOCUMENT = 'CLIENT_DOCUMENT',
  CAREGIVER_DOCUMENT = 'CAREGIVER_DOCUMENT',
  CONTACT_DOCUMENT = 'CONTACT_DOCUMENT',
}

export enum UpdateClientModalEnum {
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  ADDRESS = 'Address',
  CITY = 'City',
  STATE = 'State',
  POST_CODE = 'Post Code',
  MOBILE_PHONE = 'Mobile Phone',
  LOCATION = 'Location',
  REFERRER = 'Referrer',
  TIME_ZONE = 'Time Zone',
}

export enum CreateClientEnum {
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  ADDRESS = 'Address',
  CITY = 'City',
  STATE = 'State',
  POSTAL_CODE = 'Postal Code',
  MOBILE_PHONE = 'Mobile Phone',
  LOCATION = 'Location',
  REFERRER = 'Referrer',
  TIME_ZONE = 'Time Zone',
  CARE_CLIENT_TYPE = 'Type',
  LINK_TO_QUICK_BOOKS = 'Link To QuickBooks',
  QUICK_BOOKS_CUSTOMER_ID = 'Choose QuickBooks Customer',
}

export enum UpdateClientNameModalEnum {
  AVATAR_ACCOUNT = 'avatarAccount',
  PROFILE_CLIENT = 'profileClient',
}

export enum AddCaregiverNameModalEnum {
  AVATAR_ACCOUNT = 'avatarAccount',
  PROFILE_IMAGE = 'profileImage',
}

export enum CaregiverNameEnum {
  PROFILE_CAREGIVER = 'profileCaregiver',
}

export enum TabNotesEnum {
  NOTE = 'note',
}

export enum PageViewScheduleEnum {
  CLIENTS = 'clients',
  CAREGIVER = 'caregiver',
  OFFICE = 'office',
  OPEN_SHIFT = 'open-shift',
}

export enum ViewScheduleEnum {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}

export enum ViewCalendarEnum {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
}

export enum FieldTypeEnum {
  ASSIGNEE = 'ASSIGNEE',
  DESCRIPTION = 'DESCRIPTION',
  DUE_DATE = 'DUE_DATE',
  RELATES = 'RELATES',
}

export enum FieldTypeEmployeesEnum {
  NAME = 'NAME',
  ID = 'ID',
  EMAIL = 'EMAIL',
  JOIN_DATE = 'JOIN_DATE',
  ROLE = 'ROLE',
}

export enum FieldTypeApplicantEnum {
  NAME = 'NAME',
  ADDRESS = 'ADDRESS',
  EMAIL = 'EMAIL',
  SOURCE = 'SOURCE',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export enum ActivitiesBusinessTypeEnum {
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  CREATE_SCHEDULE = 'CREATE_SCHEDULE',
  NEW_TASK = 'NEW_TASK',
}

export enum RelatesClientTypeEnum {
  CLIENT = 'CLIENT',
  PROSPECT = 'PROSPECT',
  REFERRAL = 'REFERRAL',
}

export enum FilterParamTaskEnum {
  FIELDS = 'fields',
}

export enum FilterApplicantEnum {
  FIELDS = 'fields',
}

export enum RecurrenceEnum {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum FormTaskDateTypeEnum {
  NEVER = 'NEVER',
  UNTIL = 'UNTIL',
}

export enum DayOfWeekEnum {
  SUN = 0,
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
}

export enum MonthlyOccurrenceEnum {
  DAY_OF_MONTH = 'DAY_OF_MONTH',
}

export enum ModeTextEditorEnum {
  VIEW = 'view',
  CREATE = 'create',
  EDIT = 'edit',
}

export enum ThemeTextEditorEnum {
  SNOW = 'snow',
  BUBBLE = 'bubble',
}

export enum ActivityTypeEnum {
  CREATE_TASK = 'CREATE_TASK',
  UPDATE_TASK = 'UPDATE_TASK',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  SEND_EMAIL = 'SEND_EMAIL',
  CHANGE_STAGE = 'CHANGE_STAGE',
  CHANGE_STATUS = 'CHANGE_STATUS',
  CREATE_SHIFT_NOTE = 'CREATE_SHIFT_NOTE',
  CREATE_APPLICANT = 'CREATE_APPLICANT',
  CREATE_PROSPECT = 'CREATE_PROSPECT',
  CREATE_SHIFT = 'CREATE_SHIFT',
  CREATE_CLIENT = 'CREATE_CLIENT',
  CREATE_CAREGIVER = 'CREATE_CAREGIVER',
  CHECK_OUT = 'CHECK_OUT',
  CHECK_IN = 'CHECK_IN',
  CREATE_OPEN_SHIFT = 'CREATE_OPEN_SHIFT',
  DECLINED_OPEN_SHIFT = 'DECLINED_OPEN_SHIFT',
  APPROVED_OPEN_SHIFT = 'APPROVED_OPEN_SHIFT',
}

export enum BusinessModelEnum {
  TASK = 'TASK',
  DOCUMENT = 'DOCUMENT',
  EMAIL = 'EMAIL',
  STATUS = 'STATUS',
  STAGE = 'STAGE',
  SHIFT_NOTE = 'SHIFT_NOTE',
  SHIFT = 'SHIFT',
  CLIENT = 'CLIENT',
  CAREGIVER = 'CAREGIVER',
  TIME_CLOCK = 'TIME_CLOCK',
  OPEN_SHIFT = 'OPEN_SHIFT',
}

export enum FormTaskEnum {
  STATUS = 'status',
  RECURRENCE = 'recurrence',
  END_TYPE = 'endType',
  DESCRIPTION = 'description',
  REPEAT_MONTH_ON = 'repeatMonthOn',
  UNTIL_DATE = 'untilDate',
  DUE_DATE = 'dueDate',
  TO_DATE = 'toDate',
  FROM_DATE = 'fromDate',
  ASSIGNEE = 'assignee',
  RELATES = 'relates',
  REPEAT_EVERY = 'repeatEvery',
  REPEAT_WEEK_ON = 'repeatWeekOn',
  SEND_REMINDER_EMAIL = 'isSendReminderEmail',
  HIRE_DATE = 'hireDate',
}

export enum FormShiftEnum {
  START_DATE = 'startDate',
  START_TIME = 'startTime',
  END_DATE = 'endDate',
  END_TIME = 'endTime',
  FORM = 'from',
  TO = 'to',
  TASK = 'task',
  STATUS = 'status',
}

export enum FormTimeClockEnum {
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  DURATION = 'duration',
  STATUS = 'status',
}

export enum FormOpenShiftEnum {
  START_DATE = 'startDate',
  START_TIME = 'startTime',
  END_DATE = 'endDate',
  END_TIME = 'endTime',
  TASK = 'tasks',
  FORM = 'from',
  TO = 'to',
}

export enum ActivitySendEmailDetailEnum {
  CONTENT = 'CONTENT',
  FILES = 'FILES',
}

export enum CarePlanKeyEnum {
  MATCH_CRITERIA = 'MATCH_CRITERIA',
  CARE_NEEDS = 'CARE_NEEDS',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  ADLS = 'ADLS',
  IADLS = 'IADLS',
  ICD9_CODES = 'ICD9_CODES',
  ICD10_CODES = 'ICD10_CODES',
  UB_04_CODES = 'UB_04_CODES',
  MEDICAL_CONDITIONS = 'MEDICAL_CONDITIONS',
  MENTAL_BEHAVIOR_CONDITIONS = 'MENTAL_BEHAVIOR_CONDITIONS',
  ALLERGIES = 'ALLERGIES',
  ELIMINATIONS = 'ELIMINATIONS',
  MEDICATIONS = 'MEDICATIONS',
  AMBULATIONS = 'AMBULATIONS',
  TRANSFERS = 'TRANSFERS',
  BATHING_GROOMING = 'BATHING_GROOMING',
  MEALS = 'MEALS',
  DRIVING = 'DRIVING',
  EXERCISES = 'EXERCISES',
  SLEEP_PATTERNS = 'SLEEP_PATTERNS',
  EQUIPMENT = 'EQUIPMENT',
  PET_CARE = 'PET_CARE',
  DAILY_ROUTINE = 'DAILY_ROUTINE',
}

export enum CarePlanQuickLinkEnum {
  CARE_NEEDS = 'CARE_NEEDS',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  MEDICAL_CONDITIONS = 'MEDICAL_CONDITIONS',
  MENTAL_BEHAVIOR_CONDITIONS = 'MENTAL_BEHAVIOR_CONDITIONS',
  ALLERGIES = 'ALLERGIES',
  ELIMINATION = 'ELIMINATION',
  AMBULATION = 'AMBULATION',
  MEALS = 'MEALS',
  EXERCISE = 'EXERCISE',
  DAILY_ROUTINE = 'DAILY_ROUTINE',
}

export enum DRNDemographicEnum {
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
}

export enum MedicalConditionEnum {
  GOOD = 'GOOD',
  POOR = 'POOR',
  NONE = 'NONE',
  DEAF = 'DEAF',
  BLIND = 'BLIND',
  HEARING_AID = 'HEARING_AID',
  NONVERBAL = 'NONVERBAL',
  SIGN_LANGUAGE = 'SIGN_LANGUAGE',
  GLASSES = 'GLASSES',
  DYSPHAGIA_DX = 'DYSPHAGIA_DX',
  SMOKER = 'SMOKER',
  ON_OXYGEN = 'ON_OXYGEN',
  FEEDING_TUBE = 'FEEDING_TUBE',
  COLOSTOMY = 'COLOSTOMY',
  URINARY_CATHETER_ANY = 'URINARY_CATHETER_ANY',
  TRACHESTOMY = 'TRACHESTOMY',
}

export enum CarePlanPrognosisEnum {
  POOR = 'POOR',
  GUARDED = 'GUARDED',
  FAIR = 'FAIR',
  GOOD = 'GOOD',
  EXCELLENT = 'EXCELLENT',
}

export enum AmbulationFallRiskEnum {
  FALL_RISK = 'FALL_RISK',
  NO_HISTORY = 'NO_HISTORY',
  POOR_BALANCE = 'POOR_BALANCE',
}

export enum AmbulationAidsEnum {
  CANE = 'CANE',
  WALKER = 'WALKER',
  WHEEL_CHAIR = 'WHEEL_CHAIR',
  GERI_CHAIR = 'GERI_CHAIR',
  SCOOTER = 'SCOOTER',
}

export enum AmbulationArmsOrHandsEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum ActivityDetailEMAILEnum {
  TO = 'to',
  FROM = 'from',
  SUBJECT = 'subject',
  CONTENT = 'content',
  FILES = 'files',
}

export enum KeyboardEnum {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  TAB = 'Tab',
  SPACE = ' ',
  ENTER = 'Enter',
}

export enum ConnectionStatusEnum {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  DISCONNECTED = 'disconnected',
}

export enum TabQuerySettingsEnum {
  INTEGRATION = 'integration',
  RATE = 'rate',
  STAGE = 'stage',
  EMAIL_TEMPLATE = 'email_template',
}

export enum CurrencyEnum {
  USD = 'USD',
}

export enum QuickBooksStatusEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum CareTypeClientEnum {
  OTHER = 'OTHER',
  HOME_CARE = 'HOME_CARE',
  DAY_CENTER = 'DAY_CENTER',
  TRANSPORTATION = 'TRANSPORTATION',
}

export enum RadioCheckboxTypeEnum {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

export enum StatusNotificationEnum {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export enum BusinessNotificationTypeEnum {
  TASK = 'TASK',
}

export enum NotificationStatusEnum {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export enum MasterDataTypeEnum {
  SKILL = 'SKILL',
  TAG = 'TAG',
  STAGE_PROSPECT = 'PROSPECT',
  STAGE_APPLICANT = 'APPLICANT',
  EMAIL = 'EMAIL',
}

export enum TypeAddressEnum {
  LOCALITY = 'locality',
  STATE = 'administrative_area_level_1',
  ZIP_CODE = 'postal_code',
  COUNTRY = 'country',
}

export enum ReferrerTypeEnum {
  NONE = 'NONE',
  SELF = 'SELF',
}

export enum FileTypeExtEnum {
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
}

export enum ColorEnum {
  BLUE = 'blue',
  ORANGE = 'orange',
  FINN = 'finn',
}

export enum ScheduleTypeEnum {
  SHIFT = 'SHIFT',
  OPEN_SHIFT = 'OPEN_SHIFT',
}

export enum BusinessTypeEnum {
  CLIENT = 'CLIENT',
  CAREGIVER = 'CAREGIVER',
}

export enum SchedulesLiveViewTypeEnum {
  CLIENT = 'CLIENT',
  CAREGIVER = 'CAREGIVER',
}

export enum TypeRateEnum {
  HOURLY = 'HOURLY',
  PER_VISIT = 'PER_VISIT',
}

export enum TypeUserEnum {
  CLIENT = 'CLIENT',
  CAREGIVER = 'CAREGIVER',
}

export enum TypeSendEmailEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum StatusShiftTypeEnum {
  COMPLETE = 'COMPLETE',
  NOT_COMPLETE = 'NOT_COMPLETE',
  SCHEDULED = 'SCHEDULED',
  OPEN_SHIFT = 'OPEN_SHIFT',
  NOT_SCHEDULED = 'NOT_SCHEDULED',
  CANCELLED_BY_CLIENT = 'CANCELLED_BY_CLIENT',
  CANCELLED_BY_CAREGIVER = 'CANCELLED_BY_CAREGIVER',
  CANCELLED_BY_OFFICE = 'CANCELLED_BY_OFFICE',
  TENTATIVE = 'TENTATIVE',
  ATTENTION_REQUIRED = 'ATTENTION_REQUIRED',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  CAREGIVER_NO_SHOW = 'CAREGIVER_NO_SHOW',
  SHOW_ALL = 'SHOW_ALL',
}

export enum FormRateTypeEnum {
  NAME = 'name',
  RATE_TYPE = 'rateType',
  USER_TYPE = 'userType',
}

export enum FileKeyEnum {
  FILES = 'files',
}

export enum MessageFileTypeEnum {
  IMAGE = 'IMAGE',
  FILE = 'FILE',
  MEDIA = 'MEDIA',
}

export enum FieldTypeRate {
  NAME = 'NAME',
  RATE_TYPE = 'RATE_TYPE',
  USER_TYPE = 'USER_TYPE',
}

export enum KeyFormEmployeeEnum {
  AVATAR_ACCOUNT = 'avatarAccount',
}

export enum StatusFilterEnum {
  ALL = 'ALL',
  ACTIVATE = 'ACTIVE',
  ON_HOLD = 'ON_HOLD',
  TERMINATED = 'TERMINATED',
  DEACTIVATE = 'INACTIVE',
  OPEN = 'OPEN',
  SENT = 'SENT',
  SENT_INVOICE = 'SENT_INVOICE',
  SENT_PAYMENT = 'SENT_PAYMENT',
  PAID = 'PAID',
  DUE_DATE = 'DUE_DATE',
}

export enum BusinessTypeScheduleEnum {
  SHIFT = 'SHIFT',
  UNAVAILABILITY = 'UNAVAILABILITY',
}

export enum ContactTypeEnum {
  FAMILY = 'FAMILY',
  FRIEND = 'FRIEND',
  INSURANCE_COMPANY = 'INSURANCE_COMPANY',
  MEDICAL_PROFESSIONAL = 'MEDICAL_PROFESSIONAL',
  NEIGHBOR = 'NEIGHBOR',
  OTHER = 'OTHER',
}

export enum ReferralRoleEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
}

export enum InitialCallFormEnum {
  ADD_NEW = 'ADD_NEW',
  SELECT_EXISTING = 'EXISTING',
  SELF = 'SELF',
  SAME_AS_SELLER = 'SAME_AS_SELLER',
}

export enum ReferralRoleFilterReferralSourceEnum {
  ALL = 'ALL',
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
}

export enum ViewCLientStatusEnum {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
}

export enum ContactFormEnum {
  ADD_NEW = 'ADD_NEW',
  SELECT_EXISTING = 'EXISTING',
}

export enum ContactPermissionTypeEnum {
  PRIMARY = 'PRIMARY',
  EMERGENCY = 'EMERGENCY',
  POA = 'POA',
  PAYER = 'PAYER',
  E_PAYER = 'E_PAYER',
  EMAIL_INVOICES = 'EMAIL_INVOICES',
  PRINT_ON_CARE_PLAN = 'PRINT_ON_CARE_PLAN',
}

export enum TabQueryContactDetailEnum {
  INFO = 'info',
  CLIENTS_PROSPECTS = 'clients-prospects',
  DOCUMENTS = 'documents',
}

export enum TypeStageFilterEnum {
  ALL = 'ALL',
  PROSPECT = 'PROSPECT',
  APPLICANT = 'APPLICANT',
}

export enum StageTypeEnum {
  PROSPECT = 'PROSPECT',
  APPLICANT = 'APPLICANT',
}

export enum TabQueryRequestApplicantDetailEnum {
  INFO = 'info',
  SKILL = 'skill',
  CHECK = 'check',
  ACTIVITIES = 'activities',
}

export enum TypeCaregiverEnum {
  CAREGIVER = 'CAREGIVER',
  APPLICANT = 'APPLICANT',
  REFERRAL = 'REFERRAL',
}

export enum FileDocumentTypeEnum {
  IMAGE = 'image',
  ANY = 'any',
  PDF = 'pdf',
}

export enum AddFileEnum {
  FILES = 'files',
}

export enum KeyFormRequestApplicantEnum {
  PROFILE_IMAGE = 'profileImage',
}

export enum KeyFormRequestReferralEnum {
  PROFILE_IMAGE = 'profileImage',
}

// Skill Enum
export enum GeneralSkillEnum {
  DEMENTIA_EXPERIENCE = 'DEMENTIA_EXPERIENCE',
  HOSPICE_EXPERIENCE = 'HOSPICE_EXPERIENCE',
  INCONTINENCE_EXPERIENCE = 'INCONTINENCE_EXPERIENCE',
  INSURED_AUTOMOBILE = 'INSURED_AUTOMOBILE',
  SMOKES = 'SMOKES',
}

export enum TransfersSkillEnum {
  GAIT_BELT_EXPERIENCE = 'GAIT_BELT_EXPERIENCE',
  HOYER_LIFT_EXPERIENCE = 'HOYER_LIFT_EXPERIENCE',
}

export enum PetsSkillEnum {
  OK_WITH_CATS = 'OK_WITH_CATS',
  OK_WITH_DOGS = 'OK_WITH_DOGS',
}

export enum OtherOrMiscSkillEnum {
  CLIENT_SPECIFIC = 'CLIENT_SPECIFIC',
  CLTC = 'CLTC',
  EP = 'EP',
}

export enum TimeClockQueryTypeEnum {
  CAREGIVER = 'CAREGIVER',
  EMPLOYEE = 'EMPLOYEE',
}

export enum BusinessTimeClockEnum {
  SHIFT = 'SHIFT',
  TASK = 'TASK',
}

export enum AddressTypesEnum {
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  POLITICAL = 'political',
  AREA_LEVEL_1 = 'administrative_area_level_1',
  POSTAL_CODE = 'postal_code',
  LOCALITY = 'locality',
  COUNTRY = 'country',
}

export enum TypeCareAlertEnum {
  ALL_ALERTS = 'ALL_ALERTS',
  MISSED_CLOCK_INS = 'MISSED_CLOCK_INS',
  LATE_CLOCK_INS = 'LATE_CLOCK_INS',
  UPDATE_SHIFT = 'UPDATE_SHIFT',
  NOTE_SHIFT = 'NOTE_SHIFT',
  BIRTHDAY = 'BIRTHDAY',
  CLIENT_NO_SHIFT = 'CLIENT_NO_SHIFT',
  BIRTHDAY_CAREGIVER = 'BIRTHDAY_CAREGIVER',
  BIRTHDAY_CLIENT = 'BIRTHDAY_CLIENT',
}

export enum TimelineAlertEnum {
  LAST_TWO_HOURS = 2,
  LAST_TWELVE_FOURS_HOURS = 24,
}

export enum InvoiceTermEnum {
  AGENCY_DEFAULT = 'AGENCY_DEFAULT',
  DUE_UPON_RECEIPT = 'DUE_UPON_RECEIPT',
  NET_7 = 'NET_7',
  NET_10 = 'NET_10',
  NET_15 = 'NET_15',
  NET_30 = 'NET_30',
  NET_45 = 'NET_45',
  NET_60 = 'NET_60',
}

export enum PayHourRateEnum {
  AGENCY_SETTING = 'AGENCY_SETTING',
  OFFICIAL_TIME = 'OFFICIAL_TIME',
  ACTUAL_TIME = 'ACTUAL_TIME',
  SCHEDULED_TIME = 'SCHEDULED_TIME',
}

export enum InvoiceRemittanceAddressEnum {
  AGENCY_DEFAULT = 'AGENCY_DEFAULT',
  ELITE_HOME_CARE_LLC = 'ELITE_HOME_CARE_LLC',
  ELITE_DAY_CENTER_OF_GREENVILLE = 'ELITE_DAY_CENTER_OF_GREENVILLE',
}

export enum OvertimeMethodRateEnum {
  AGENCY_DEFAULT = 'AGENCY_DEFAULT',
  STRAIGHT_TIME = 'STRAIGHT_TIME',
  WEIGHTED_AVERAGE = 'WEIGHTED_AVERAGE',
  BLENDED_AVERAGE = 'BLENDED_AVERAGE',
}

export enum BusinessTypeSkillEnum {
  CAREGIVER = 'CAREGIVER',
  APPLICANT = 'APPLICANT',
}

export enum LinkToQuickBookCustomerEnum {
  NO = 'NO',
  ADD_NEW = 'ADD_NEW',
  ADD_EXISTING = 'ADD_EXISTING',
}

export enum PageTimeClockEnum {
  CAREGIVER = 'CAREGIVER',
  EMPLOYEE = 'EMPLOYEE',
}

export enum LinkToCaregiverListEnum {
  NO = 'NO',
  ADD_EXISTING = 'ADD_EXISTING',
}

export enum HrPageEnum {
  CAREGIVER = 'CAREGIVER',
  EMPLOYEE = 'EMPLOYEE',
}

export enum HrListTypeEnum {
  REQUEST = 'REQUEST',
  HISTORY = 'HISTORY',
}

export enum HrStatusEnum {
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  REJECTED = 'REJECTED',
}

export enum HomeSafetyAssessmentMenuEnum {
  ENTRANCE_FRONT_YARD_SAFETY = 'ENTRANCE_FRONT_YARD_SAFETY',
  HALLWAY_FOYER_SAFETY = 'HALLWAY_FOYER_SAFETY',
  LIVING_DINING_FAMILY_ROOM_SAFETY = 'LIVING_DINING_FAMILY_ROOM_SAFETY',
  KITCHEN_SAFETY = 'KITCHEN_SAFETY',
  STAIRWAYS_SAFETY = 'STAIRWAYS_SAFETY',
  BEDROOM_SAFETY = 'BEDROOM_SAFETY',
  BATHROOM_SAFETY = 'BATHROOM_SAFETY',
  BASEMENT_SAFETY = 'BASEMENT_SAFETY',
  LAUNDRY_SAFETY = 'LAUNDRY_SAFETY',
  GARAGE_SAFETY = 'GARAGE_SAFETY',
  BACK_YARD_SAFETY = 'BACK_YARD_SAFETY',
}

export enum AnswerHomeAssessmentEnum {
  YES = 'YES',
  NO = 'NO',
  NA = 'N/A',
}

export enum AuthorizationStatusEnum {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  SUSPENDED = 'SUSPENDED',
  DECEASED = 'DECEASED',
}

export enum AuthorizationFrequencyEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  TWO_WEEK = 'TWO_WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
  AUTHORIZATION = 'AUTHORIZATION',
}
